// accounting_global.svg
const s3_path = (name) => `https://be-global-erp-prod-bucket.s3.eu-west-3.amazonaws.com/images/${name}`
export const images = {
    header: s3_path('logo.png'),
    loading: 'https://media.giphy.com/media/3o7bu3XilJ5BOiSGic/giphy.gif',
    authBg: s3_path('image-2.svg'),
    authBg2: s3_path('image-3.svg'),
    authBg3: s3_path('image-4.svg'),
    authBg4: s3_path('image-5.svg'),
    logo: s3_path('logo.svg'),
    item: s3_path('item.svg'),
    posShortLet: s3_path('short-let.svg'),
    table: s3_path('table.svg'),
    venue: s3_path('venue.svg'),
    food: s3_path('food.svg'),
    admin_global: s3_path('admin_global.svg'),
    operator_global: s3_path('operator_global.svg'),
    pos_global: s3_path('pos_global.svg'),
    kitchen_global: s3_path('kitchen_global.svg'),
    waiter_global: s3_path('waiter_global.svg'),
    accounting_global: s3_path('accounting_global.svg'),
    avatar: s3_path('Avatar.svg'),

    

    pos_card_short: s3_path('PosCardImages/shortLet.svg'),
    pos_card_food: s3_path('PosCardImages/orderFood.svg'),
    pos_card_table: s3_path('PosCardImages/table.svg'),
    pos_card_bookMedia: s3_path('PosCardImages/bookMedia.svg'),
    pos_card_venue: s3_path('PosCardImages/bookVenue.svg'),
    pos_card_studio: s3_path('PosCardImages/studioSession.svg'),
    pos_card_photo: s3_path('PosCardImages/photoShoot.svg'),
    pos_card_video: s3_path('PosCardImages/videoShoot.svg'),
    pos_card_tools: s3_path('PosCardImages/studioTools.svg'),



    // orderFood: s3_path('orderFood.svg'),
    // shortLet: s3_path('shortLet.svg'),
    // ReserveTable: s3_path('ReserveTable.svg'),
    // BookMedia: s3_path('BookMedia.svg'),
    // bookVenue: s3_path('bookVenue.svg'),
    // studioSession: s3_path('studioSession.svg'),
    // photoShoot: s3_path('photoShoot.svg'),
    // videoShoot: s3_path('videoShoot.svg'),
    // studioTools: s3_path('studioTools.svg'),
    // posShortLet: s3_path('pos-short-let.svg')).default
}