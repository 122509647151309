import React, { useCallback, useEffect, useState } from 'react'
// import { Box } from '@mui/material'
import {  FlexText, Input, SearchResult, Text } from 'Components'
import { icons } from 'Assets/icons'
import { apiRequest } from 'Services'
import { useDispatch } from 'react-redux'
import { assignCustomer } from 'store/slices/customerSlice'

export const SelectCustomer = ({ state = 'existing', children, prev = () => null, next = () => null, onChange=()=>null, getCustomer = () => null }) => {
  const [customer, setCustomer] = useState(state)
  const [formValues, setFormValues] = useState({})
  const [customerFound, setCustomerFound] = useState(null)
  const [selectedCustomer, setSelctedCustomer] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    onChange(customer)
  }, [customer])

  const handleChange = (e) => {
    setFormValues(prev => ({ ...prev, [e?.target?.name]: e.target.value }))
  }

  useEffect(() => {
    if (formValues?.searchCustomer) {
      const search = async () => {
        const data = await apiRequest({ method: 'get', url: `/operations/customers/search?query=${formValues?.searchCustomer}` })
        setCustomerFound(data?.data?.data);
      }
      search()
    } else {
      setCustomerFound(null)
    }
  }, [formValues])

  const createNew = async (done) => {

    try {
      const { data } = await apiRequest({
        url: '/shop/customers/new-customer', method: 'post', payload: {
          full_name: formValues?.full_name,
          phone: formValues?.phone,
          email:formValues?.email
        }
      })
      if (customer) dispatch(assignCustomer(data.data))
      setSelctedCustomer(data?.data)
      getCustomer(data?.data)
      next()
    } catch (error) {

    }

  }

  const goNext = () => {
    if (customer === 'existing') {
      next()
    } else {
      createNew()
    }
  }
 
  // )
  const tabs = [
    { content: 'Existing customers', onClick: () => setCustomer('existing'), key: 'existing' },
    { content: 'New customers', onClick: () => setCustomer('new'), key: 'new' }
  ]
  const selectCustomer = useCallback((customer) => {
    setSelctedCustomer(customer);
    if (customer) dispatch(assignCustomer(customer))
    // console.log(customer)
    getCustomer(customer)
    setCustomerFound(null);
  }, [])
  return <>
    {/* <Tabs tabs={tabs} active={customer} /> */}
    {customer === 'existing' && <Input icon={icons.search} required placeholder='Search ID/Phone number' onChange={handleChange} name='searchCustomer' label='Search customer' />}
    {customer === 'existing' &&
      (customerFound?.length === 0 ?
        <Text weight='700' size='12px' color='red' align='center'>No customer found</Text>
        :
        (
          customerFound === null ? '' : (<SearchResult>
            {customerFound?.map(x => (<div key={x?.id} onClick={() => { selectCustomer(x); dispatch(assignCustomer(x)) }}>
              <Text size='16px' weight='400' lHeight='24px'>{x?.full_name}</Text>
              <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>{x?.phone}</Text>
              <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>{x?.email}</Text>
            </div>
            ))}
          </SearchResult>)
        ))}



    {
      selectedCustomer && customer === 'existing' && (
        <>
          <FlexText leftText='Customer ID' rightText={selectedCustomer?.id} />
          <FlexText leftText='Customer name' rightText={selectedCustomer?.full_name} />
          <FlexText leftText='Phone number' rightText={selectedCustomer?.phone} />
          <FlexText leftText='Email' rightText={selectedCustomer?.email} />
          <FlexText leftText='Membership' rightText={selectedCustomer?.type} />
        </>
      )
    }
    {children}

    {/* <BtnStack leftClick={prev} rightClick={goNext} /> */}
  </>
}