import React, { useEffect, useMemo, useState } from 'react';
import styles from './PurchaseOrder.module.scss';
import { Header } from 'Partial/Header';
import { BsPlus } from 'react-icons/bs';
import { Button, DeleteModal, FlexText, Table, Text } from 'Components';
import { icons } from 'Assets/icons';
import { BsFillBellFill } from 'react-icons/bs'
import VerticalProgressStep from 'Components/ProgressStep/VerticalProgress';
import { AiTwotoneDollarCircle } from 'react-icons/ai';
import { Box } from '@mui/material';
import { ImCheckmark } from 'react-icons/im';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { apiRequest } from 'Services';
// import { ApprovePurchaseOrder } from './ApprovePurchaseOrder'
import { toast } from 'react-toastify';
import ApproveBtns from '../WorklList/ApproveBtns';
import { formatDate } from 'utils/formatPrice';
import { Check } from '@mui/icons-material';
import { openModal } from 'store/slices/modalSlice';
import { useDispatch } from 'react-redux';

const PurchaseOrder = ({ item_id }) => {
  const { id, type } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: suppliers } = useQuery('admin/suppliers')
  const { data: singlePurchaseOrder } = useQuery(`/operations/purchase-orders/${id}`, { enabled: Boolean(id) })
  const [update, setUpdate] = useState('111')
  // console.log(singlePurchaseOrder)
  const column1 = [
    { name: 'Date', key: 'created_at', render: (data) => dayjs(data?.created_at).format('DD MMM, YYYY. hh:mm') },
    { name: 'Purchase order ID', key: 'purchase_order_no' },
    { name: 'Vendor name', key: 'vendor', render: (data) => suppliers?.data?.find(x => x.id === data?.supplier_id)?.company_name },
    { name: 'Description', key: 'description' },
    { name: 'Total amount (₦ )', key: 'total' },
    { name: 'Status', key: 'status', render: (data) => <span className='capitalize'>{data?.order_status !== 'pending' ? data?.order_status : data?.status}</span> },
  ]
  const column = useMemo(() => ([
    { name: 'Inventory Item', key: 'description', render: (data) => data?.inventory_item?.name },
    { name: 'Qty', key: 'qty' },
    { name: 'Unit', key: 'boh_unit' },
    { name: 'Unit price', key: 'unit_price' },
    { name: 'Total price', key: 'total_price' },
  ]), [])
  // const ≥
  const [columns, setColumns] = useState(column1)
  const mark_as_delivered = async (id) => {
    try {
      await apiRequest({
        url: `/operations/purchase-orders/${id}/mark-as-delivered`,
        hasAuth: true
      })
      setUpdate(Math.random())
      toast.success('Successful')
    } catch (error) {
      console.log(error)

    }
  }

  useEffect(() => {
    setColumns(id ? column : column1)
  }, [id, column, column1])
  const Head = () => (
    <div className={styles.header}>
      <div>
        <Text size='12px' weight='600' lHeight='18px'>
          Vendor name
        </Text>
        <Text size='16px' weight='400' lHeight='24px'>
          {singlePurchaseOrder?.data?.supplier?.company_name}
        </Text>
      </div>
      <div>
        <Text size='12px' weight='600' lHeight='18px'>
          Purchase order ID
        </Text>
        <Text size='16px' weight='400' lHeight='24px'>
          {singlePurchaseOrder?.data?.purchase_order_no}
        </Text>
      </div>
      <div>
        <Text size='12px' weight='600' lHeight='18px'>
          Vendor address
        </Text>
        <Text size='16px' weight='400' lHeight='24px'>
          {singlePurchaseOrder?.data?.supplier?.address}
        </Text>
      </div>
      <div>
        <Text size='12px' weight='600' lHeight='18px'>
          Date
        </Text>
        <Text size='16px' weight='400' lHeight='24px'>
          {dayjs(singlePurchaseOrder?.data?.created_at).format("MMM DD, YYYY. hh:mm")}
        </Text>
      </div>
    </div>
  )

  const Footer = () => (
    <div className={styles.footer}>

      <FlexText leftText='Tax' rightText={singlePurchaseOrder?.data?.tax} />
      <FlexText leftText='Shipping' rightText={singlePurchaseOrder?.data?.shipping_cost} />
      <FlexText leftText='Sub total' rightText={singlePurchaseOrder?.data?.sub_total} />
      <FlexText leftText='Total' rightText={singlePurchaseOrder?.data?.total} />

    </div>
  )
  const currentstatus = useMemo(() => {

    if (singlePurchaseOrder?.data?.order_status === 'delivered') return 2
    if (singlePurchaseOrder?.data?.status === 'approved') return 1
    if (singlePurchaseOrder?.data?.status === 'pending') return 0


  }, [singlePurchaseOrder?.data])

  const Right = () => (

    <div className={styles.right}>

      {type && <ApproveBtns item_id={item_id} />}

      <header className={styles.rightHeader}>

        <BsFillBellFill />
        <Text weight='600' lHeight='24px' size='16px' >Updates{currentstatus}</Text>
      </header>
      <div className={styles.rightBody}>
        <VerticalProgressStep current={currentstatus + 1} points={[
          {
            icon: <Check color='red' />, content: <Box>
              <Text size='16px' weight='400' lHeight='24px'>Pending</Text>
              <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.created_at).format('MMM DD, YYYY. hh:mm A')}</Text>
            </Box>
          },
          {
            icon: singlePurchaseOrder?.data?.status === 'approved' ? <ImCheckmark /> : <AiTwotoneDollarCircle fill='white' />, content: <Box>
              <Text size='16px' weight='400' lHeight='24px'>  Approved</Text>
              {singlePurchaseOrder?.data?.approved_at && <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.approved_at).format('MMM DD, YYYY. hh:mm A')}</Text>
              } </Box>
          },
          {
            icon: singlePurchaseOrder?.data?.order_status === 'delivered' ? <ImCheckmark /> : <AiTwotoneDollarCircle fill='white' />, content: <Box>
              <Text size='16px' weight='400' lHeight='24px'>Delivered</Text>
              {singlePurchaseOrder?.data?.delivered_at && <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.delivered_at).format('MMM DD, YYYY. hh:mm A')}</Text>
              }  </Box>
          },
          // {
          //   icon: <AiTwotoneDollarCircle fill='white' />, content: <Box>
          //     <Text size='16px' weight='400' lHeight='24px'>Order completed</Text>
          //     <Text weight='600' size='12px' lHeight='18px'>June 2, 2023 • 15:24</Text>
          //   </Box>
          // },
        ]} />
      </div>
      <div>
        <Text size='18px'>Remark:</Text>
        <Text size='14px'>No remark</Text>
      </div>


    </div>
  )
  return (
    <div className={styles.body}>

      <Header
        name='Purchase orders'
        text={id ? '' : <><BsPlus /> Create purchase order</>}
        // buttonOnclick={() => dispatch(openModal({ title: 'Add purchase order', content: <AddPurchaseOrder /> }))}
        buttonOnclick={() => navigate('/operations/purchaseorder/add')}
      />

      <div className={styles.container}>
        <div className={styles.left}>
          {id && <Head />}
          {id ? <Table
            column={column}
            // url={`/operations/purchase-orders/${id}`}
            data={singlePurchaseOrder?.data?.entries}
            action={false}
            actions={(data) => (
              <div>
                <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => navigate(`/operations/purchaseorder/${data?.id}/`)} > {icons.viewDetail} View Details</Button>
                {/* <Button bgcolor='inherit ' shadow={false} color='black' > {icons.edit} Edit</Button> */}
                {data?.status === 'pending' && <Button bgcolor='inherit ' 
                  onClick={() => dispatch(openModal({ title: 'Delete confirmation', content: <DeleteModal message={'Do you want to delete this purchase order?'} url={`/admin/purchaseorder/${data?.id}`} /> }))}
                shadow={false} color='#EB2F2F'>  {icons.delete} Delete</Button>}
              </div>
            )}
          /> :
            <Table
              key={update}
              column={column1}
              url={'/operations/purchase-orders'}
              actions={(data) => (
                <div>
                  <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => navigate(`/operations/purchaseorder/${data?.id}/`)} > {icons.viewDetail} View Details</Button>
                  {
                    (data?.status === 'approved' && data?.delivered_at === null) &&
                    <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => mark_as_delivered(data?.id)} > {icons.viewDetail} Mark as delivered</Button>
                  }
                  {/* <Button bgcolor='inherit ' shadow={false} color='black' > {icons.edit} Edit</Button> */}
                  {data?.status === 'pending' && <Button bgcolor='inherit ' shadow={false} color='#EB2F2F'  onClick={() => dispatch(openModal({ title: 'Delete confirmation', content: <DeleteModal message={'Do you want to delete this purchase order?'} url={`/operations/purchase-orders/${data?.id}`} /> }))}
                 >  {icons.delete} Delete</Button>
                  } </div>
              )}
            />}
          {id && <Footer />}
        </div>
        {id && <Right />}
      </div>
    </div>
  );
};

export default PurchaseOrder;
