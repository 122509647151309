import React from "react";
import styles from "./layout.module.scss";
import { PropTypes } from "prop-types";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
// import { check_session } from 'utils/check_session';

function Layout({ name, children, show }) {
  // window.addEventListener('click',()=>{
  //   check_session()
  // })

  return show ? (
    <div className={styles.layout}>
      <div className="flex flex-row justify-center items-center">
        <Navbar
          text_className="text-black"
          className="w-[100%]"
          title={name}
          FiChevronDown_color="black"
        />
      </div>
      <body>
        <div className={styles.sidebar}>
          {" "}
          <Sidebar />
        </div>
        <main>{children}</main>
      </body>
      {name}
    </div>
  ) : (
    children
  );
}
Layout.prototype = {
  name: PropTypes.string,
};
export default Layout;
