import { Button, Form, Input, Text } from "Components";
import { apiRequest } from "Services";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import { getPath } from "utils";

const ApproveBtns = ({ id, message }) => {
  const { item_id } = useParams();
  const navigate = useNavigate();
  const [remark, setRemark] = useState("no remark");
  // console.log(item_id)

  const action = async (status) => {
    // const path  = getPath
    try {
      await apiRequest({
        url: `/operations/worklists/${item_id}`,
        payload: {
          status, // [approved, denied]
          remark,
        },
        hasAuth: true,
        method: "patch",
      });
      toast.success(status === "approved" ? "Approved" : "Denied");
      navigate(`/${getPath()}/worklist`);
      //   dispatch(closeModal())
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col gap-3 pt-3">
      <Text> Take Action</Text>
      <Form>
        <Input
          type="text"
          placeholder="give a remark "
          label="Remark"
          onChange={(e) => setRemark(e.target.value)}
        />
      </Form>
      <div className="flex flex-col gap-3">
        <Button onClick={() => action("approved")}>Approve</Button>
        <Button
          bgcolor="#EFEFEF"
          color="#B8541B"
          className={"border"}
          onClick={() => action("denied")}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default ApproveBtns;
