import { operations_icons as oi } from "Pages/Operations/operationIcons";
import { icons } from "Assets/icons";
import { assets } from "Assets";
import { FaWallet } from "react-icons/fa";
const base = "/operations";
export const operations_paths = [
  { path: base + "/", name: "Dashboard", icon: oi.dashboard },
  {
    name: "WorkList",
    path: `${base}/WorkList`.toLowerCase(),
    icon: oi.worklist,
  }, //----
  {
    name: "Service booking",
    path: `${base}/Booking`.toLowerCase(),
    icon: oi.booking,
    children: [
      // { path: base + "/bookings/table", name: "Tables" },
      // { path: base + "/bookings/venue", name: "Venues" },
      {
        path: base + "/bookings/passport-photograph",
        name: "Passport photograph",
      },
      { path: base + "/bookings/studio-equipment", name: "Studio Equipments" },
      { path: base + "/bookings/studio-session", name: "Studio Sessions" },
      {
        path: base + "/bookings/media-service",
        name: "Professional Media Service",
      },
      { path: base + "/bookings/video-shoot", name: "Video shoot" },
      { path: base + "/bookings/photo-shoot", name: "Photo shoot" },
      { path: base + "/bookings/live-performance", name: "Live performance" },
    ],
  },
  // { name: 'Inventory', path: `${base}/Inventory`.toLowerCase(),icon: oi.inven_mgnt  }, //----
  {
    path: `${base}#`.toLowerCase(),
    name: "Inventory Management",
    icon: oi.inven_mgnt,
    children: [
      { path: base + "/inventory", name: "Inventory" },
      { path: base + "/inventory-transfer", name: "Inventory Transfer" },
      { path: base + "/purchaseorder", name: "Purchase Order" },
      // { path: base + '/manualstocktaking', name: 'Manual Stock Taking' },
      { path: base + "/manual-transactions", name: "Manual Transactions " },
    ],
  },
  {
    name: "My Customers",
    path: `${base}/MyCustomers`.toLowerCase(),
    icon: oi.my_customers,
    children: [
      { path: base + "/customers", name: "My Customers" },
      { path: base + "/reserved-accounts", name: "Reserved Account" },
    ],
  },

  // {
  //   name: "Funding",
  //   path: `${base}/funding`.toLowerCase(),
  //   icon: <FaWallet />,
  // }, //----
  // { name: "Orders", path: `${base}/Orders`.toLowerCase(), icon: oi.orders }, //----
  // {
  //   name: "Shifting",
  //   path: `${base}/Shifting`.toLowerCase(),
  //   icon: oi.shift_mgnt,
  // },

  // { name: 'Purchase Order', path: `${base}/PurchaseOrder`.toLowerCase(),icon: oi.dashboard  },
  // { name: 'Manual Stock Taking', path: `${base}/ManualStockTaking`.toLowerCase(),icon: oi.dashboard  },
  // { name: 'Manual Stock Adjustment', path: `${base}/ManualStockAdjustment`.toLowerCase(),icon: oi.dashboard  },
  {
    name: "Aging Receivable",
    path: `${base}/AgingReceivable`.toLowerCase(),
    icon: oi.aging_receivable,
  },

  {
    name: "Live band",
    path: `${base}/live-band`.toLowerCase(),
    icon: oi.aging_receivable,
  },

  { path: base + "/invoice", name: "Invoice", icon: assets.icons.dashboard },
  {
    path: `${base}#`.toLowerCase(),
    name: "Report",
    icon: icons.receipt,
    children: [
      // { path: base + '/income-report', name: 'Income Report' },
      {
        path: base + "/inventory-statement-report",
        name: "Inventory statement report",
      },
      {
        path: base + "/inventory-transaction-report",
        name: "Inventory transaction report",
      },
      { path: base + "/Sales-margin-report", name: "Sales margin report" },
      { path: base + "/forecast-report", name: "Forecast Report" },
      // { path: base + '/remittance', name: 'Remittance ' },
    ],
  },
];
