import React from 'react';
import { Header } from 'Partial/Header';
import { BsPlus } from 'react-icons/bs';
import styles from './inventorytransfer.module.scss'
import { Button, DeleteModal, Table, Text } from 'Components';
import { icons } from 'Assets/icons';
// import { BsFillBellFill } from 'react-icons/bs'
// import VerticalProgressStep from 'Components/ProgressStep/VerticalProgress';
// import { AiTwotoneDollarCircle } from 'react-icons/ai';
// import { Box } from '@mui/material';
// import { ImCheckmark } from 'react-icons/im';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { openModal } from 'store/slices/modalSlice';
import { useDispatch } from 'react-redux';
// import { apiRequest } from 'Services';
// import { ApprovePurchaseOrder } from './ApprovePurchaseOrder'
// import { toast } from 'react-toastify';
// import ApproveBtns from '../WorklList/ApproveBtns';
// import { formatDate } from 'utils/formatPrice';
// import { Check } from '@mui/icons-material';


const InventoryTransfer = ({ item_id }) => {
    const { id } = useParams()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const { data: suppliers } = useQuery('admin/suppliers')
    const { data: singleTransfer } = useQuery(`/operations/inventory-transfer/${id}`, { enabled: Boolean(id) })

    // console.log(singlePurchaseOrder)
    const column1 = [
        { name: 'Date', key: 'created_at', render: (data) => dayjs(data?.created_at).format('DD MMM, YYYY. hh:mm') },
        { name: 'Batch ID', key: 'special_id' },
        { name: 'Total items', key: 'inventory_transfers_count' },
        { name: 'Status', key: 'status' },
    ]
    const column2 = [
        { name: 'Batch ID', key: 'batch_id' },
        { name: 'Item', key: 'inventory_item', render: (data) => data?.inventory_item?.name },
        { name: 'Amount', key: 'amount' },
        { name: 'Unit', key: 'destinationUnit' },
        { name: 'From source', key: 'destination_amount', render: (data) => data?.from_inventory_source?.name },
        { name: 'To source', key: 'destination_amount', render: (data) => data?.to_inventory_source?.name },
        { name: 'Status', key: 'status' },
    ]
    // const column = useMemo(() => ([
    //     { name: 'Inventory Item', key: 'description', render: (data) => data?.inventory_item?.name },
    //     { name: 'Qty', key: 'qty' },
    //     { name: 'Unit', key: 'boh_unit' },
    //     { name: 'Unit price', key: 'unit_price' },
    //     { name: 'Total price', key: 'total_price' },
    // ]), [])
    // const ≥



    // const columns = useMemo(() => {
    //     return (id ? column : column1)
    // }, [id,column,c])
    const Head = () => (
        <div className={styles.header}>
            <div>
                <Text size='12px' weight='600' lHeight='18px'>
                    Date
                </Text>
                <Text size='16px' weight='400' lHeight='24px'>
                    {dayjs(singleTransfer?.data?.date).format('MMM DD, YYYY. hh:mmA.')}
                </Text>
            </div>
            <div>
                <Text size='12px' weight='600' lHeight='18px'>
                    Reason
                </Text>
                <Text size='16px' weight='400' lHeight='24px'>
                    {singleTransfer?.data?.reason}
                </Text>
            </div>
            <div>
                <Text size='12px' weight='600' lHeight='18px'>
                    Transfer ID
                </Text>
                <Text size='16px' weight='400' lHeight='24px'>
                    {singleTransfer?.data?.special_id}
                </Text>
            </div>
            <div>
                <Text size='12px' weight='600' lHeight='18px'>
                    Status
                </Text>
                <Text size='16px' weight='400' lHeight='24px'>
                    {(singleTransfer?.data?.status)}
                </Text>
            </div>
            <div>
                <Text size='12px' weight='600' lHeight='18px'>
                    Created by
                </Text>
                <Text size='16px' weight='400' lHeight='24px'>
                    {(singleTransfer?.data?.creator?.first_name + " " + singleTransfer?.data?.creator?.last_name)}
                </Text>
            </div>
        </div>
    )

    //   const Footer = () => (
    //     <div className={styles.footer}>

    //       <FlexText leftText='Tax' rightText={singlePurchaseOrder?.data?.tax} />
    //       <FlexText leftText='Shipping' rightText={singlePurchaseOrder?.data?.shipping_cost} />
    //       <FlexText leftText='Sub total' rightText={singlePurchaseOrder?.data?.sub_total} />
    //       <FlexText leftText='Total' rightText={singlePurchaseOrder?.data?.total} />

    //     </div>
    //   )
    //   const currentstatus = useMemo(() => {

    //     if (singlePurchaseOrder?.data?.order_status === 'delivered') return 2
    //     if (singlePurchaseOrder?.data?.status === 'approved') return 1
    //     if (singlePurchaseOrder?.data?.status === 'pending') return 0


    //   }, [singlePurchaseOrder?.data])

    //   const Right = () => (

    //     <div className={styles.right}>

    //       {type && <ApproveBtns item_id={item_id} />}

    //       <header className={styles.rightHeader}>

    //         <BsFillBellFill />
    //         <Text weight='600' lHeight='24px' size='16px' >Updates{currentstatus}</Text>
    //       </header>
    //       <div className={styles.rightBody}>
    //         <VerticalProgressStep current={currentstatus + 1} points={[
    //           {
    //             icon: <Check color='red' />, content: <Box>
    //               <Text size='16px' weight='400' lHeight='24px'>Pending</Text>
    //               <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.created_at).format('MMM DD, YYYY. hh:mm A')}</Text>
    //             </Box>
    //           },
    //           {
    //             icon: singlePurchaseOrder?.data?.status === 'approved' ? <ImCheckmark /> : <AiTwotoneDollarCircle fill='white' />, content: <Box>
    //               <Text size='16px' weight='400' lHeight='24px'>  Approved</Text>
    //               {singlePurchaseOrder?.data?.approved_at && <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.approved_at).format('MMM DD, YYYY. hh:mm A')}</Text>
    //               } </Box>
    //           },
    //           {
    //             icon: singlePurchaseOrder?.data?.order_status === 'delivered' ? <ImCheckmark /> : <AiTwotoneDollarCircle fill='white' />, content: <Box>
    //               <Text size='16px' weight='400' lHeight='24px'>Delivered</Text>
    //               {singlePurchaseOrder?.data?.delivered_at && <Text weight='600' size='12px' lHeight='18px'>{dayjs(singlePurchaseOrder?.data?.delivered_at).format('MMM DD, YYYY. hh:mm A')}</Text>
    //               }  </Box>
    //           }
    //         ]} />
    //       </div>
    //       <div>
    //         <Text size='18px'>Remark:</Text>
    //         <Text size='14px'>No remark</Text>
    //       </div>


    //     </div>
    //   )
    return (
        <div className={styles.body}>
            <Header
                name='Inventory Transfer'
                text={id ? '' : <><BsPlus /> Create inventory transfer</>}
                buttonOnclick={() => navigate('/operations/inventory-transfer/add')}
            />
            <div className={styles.container}>
                {id ?
                    <>
                        <Head />
                        <Table
                            column={column2}
                            data={singleTransfer?.data?.inventory_transfers}
                            action={false}
                        />
                    </>

                    :
                    <Table
                        column={column1}
                        url={'operations/inventory-transfer'}
                        actions={(data) => (
                            <div>
                                <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => navigate(`/operations/inventory-transfer/${data?.id}/`)} > {icons.viewDetail} View Details</Button>


                                {data?.status === 'pending' && <Button bgcolor='inherit ' shadow={false} color='#EB2F2F' onClick={() => dispatch(openModal({ title: 'Delete confirmation', content: <DeleteModal message={'Do you want to delete this transfer?'} url={`/operations/inventory-transfer/${data?.id}`} /> }))} >
                                    {icons.delete}
                                    Delete
                                </Button>}
                            </div>
                        )}
                    />}

            </div>
        </div>


    );
};

export default InventoryTransfer;
