

// let path = 'accounting'
// const locaiton = window.location.pathname
  
// console.log(locaiton)
// if (locaiton.includes('pos')) path = 'pos'
// if (locaiton.includes('operation')) path = 'operations'
// if (locaiton.includes('waiter')) path = 'waiter'


// console.log(path)
const path=()=>{
    let in_path = 'accounting'
    const locaiton = window.location.pathname
      
    console.log(locaiton)
    if (locaiton.includes('pos')) in_path = 'pos'
    if (locaiton.includes('operation')) in_path = 'operations'
    if (locaiton.includes('waiter')) in_path = 'waiter'

    return in_path
}
// window.addEventListener('p')
export {
    path
}