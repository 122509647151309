import { Box } from '@mui/material'
import { BtnStack, FlexText, Text } from 'Components'
import ReviewConfirm from 'Pages/Operations/Bookings/components/ReviewConfirm'
import ApproveBtns from 'Pages/Operations/WorklList/ApproveBtns'
import dayjs from 'dayjs'
import { useBranches } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { changeTitle, openModal } from 'store/slices/modalSlice'
import { formatDate } from 'utils/formatPrice'
import ManualStockAdjustmentDetails from '../ManualStockAdjustment/ManualStkAdjDeets'


const ManualStockApproval = ({ submitText, next = () => null, prev = () => null, title = 'Review & Confirm' }) => {
    const dispatch = useDispatch()
    // const [loading, setLoading] = useState()
    // const { branches } = useBranches() 
    const { item_id } = useParams()


    const { data: worklist, refetch } = useQuery(`/operations/worklists/${item_id}`, { enabled: Boolean(item_id) })
    // console.log(worklist?.data)
    const workable_data = worklist?.data?.workable
    // console.log(worklist?.data)
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [dispatch, title])

    return (
        <Box className={'flex flex-col  h-[100%]   mx-auto'}>



            <div className='w-[90%] flex flex-col '>
                {/* <Text size='24px' weight='600' align='center'> Manual Transaction </Text>
                <div className='flex flex-col gap-3 font-[20px]'>
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Date'} rightText={dayjs(workable_data?.data).format('MMM DD, YYYY. hh:mm A')} />


                    <FlexText leftWeight={500} rightWeight={600} leftText={'Account No'} rightText={workable_data?.account_no} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Incoming overdraft'} rightText={workable_data?.incoming_overdraft} />

                    <FlexText leftWeight={500} rightWeight={600} leftText={'Status'} rightText={` ${workable_data?.overdraft_status} `} />
                </div> */}

                <ManualStockAdjustmentDetails />


               <div className='px-3 pb-5 justify-center w-100'>
               <ApproveBtns />
               </div>
            </div>

            {/* </ReviewConfirm> */}

        </Box>
    )
}

export default ManualStockApproval;