import React from "react";
import { Button } from ".";
import { Box } from "@mui/material";

const BtnStack = ({
  leftBg = "#F7F7F7",
  leftColor = "#B8541B",
  leftText,
  rightText = "Proceed",
  leftClick = () => null,
  rightClick = () => null,
}) => {
  return (
    <Box display={"flex"} gap={"16px"} width={"100%"}>
      <Button onClick={leftClick} bgcolor={leftBg} color={leftColor}>
        {leftText || "Back"}
      </Button>
      <Button onClick={rightClick}>{rightText}</Button>
    </Box>
  );
};

export default BtnStack;
