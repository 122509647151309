import React, { useEffect, useState } from 'react'
import styles from './sidebar.module.scss'
import { useLocation, useNavigate } from 'react-router'
import { BsChevronDown } from 'react-icons/bs'
import { useMediaQuery } from '@mui/material'
import { GiHamburgerMenu } from 'react-icons/gi'
import { admin_paths } from './adminSibebar'
import { operations_paths } from './operationsSidebar'
import { pos_paths } from './posSidebar'
import { accounting_paths } from './accountingSidebar'

const modules = [
  { base: 'admin', paths: admin_paths },
  { base: 'operations', paths: operations_paths },
  { base: 'pos', paths: pos_paths },
  { base: 'accounting', paths: accounting_paths },
]
function Sidebar() {
  const navigate = useNavigate()
  const [dropped, setDropped] = useState(null)
  const reduce = useMediaQuery('(max-width:992px)')
  const [show, setShow] = useState(false)
  const [pages, setPages] = useState(modules[1].paths)
  const { pathname } = useLocation()
  // console.log(pathname)
  useEffect(() => {
    const paths = modules.find(({ paths, base }) => pathname.includes(base)).paths
    setPages(paths)
    const pathsWithChildren = paths.filter(path => path?.children)
    // console.log(pathsWithChildren)
    // console.log()
    const findChildPathinDEX = paths.indexOf(pathsWithChildren.find(path => path.children.find(child => child.path === pathname)))
    // console.log()
    setDropped(findChildPathinDEX)
  }, [pathname])
  // useEffect
  const runShow = () => {
    if (reduce) {
      setShow(prev => !prev)
    }
  }

  const changeRoute = ({ path, name, icon, children }, i) => {
    setShow(prev => false)
    if (!children) {
      navigate(path)
    } else {
      setDropped(prev => {
        if (prev === null) {
          return i
        } else if (prev === i) {
          return null
        } else {
          return i
        }
      })
    }
  }
  const Component = () => pages.map((x, i) => <button key={x.path} className={styles.bars} value={x} onClick={() => changeRoute(x, i)}>
    <p key={x.path} className={`${styles.text} ${pathname === x.path ? styles.active : ''}`} >
      <p>{x.icon} </p>
      <p>{x.name}</p>
      {x.children && <p style={{ float: 'right' }}><BsChevronDown className={styles.chevron} /></p>}
    </p>
    {x.children && dropped === i ?
      x.children.map(child => (
        <p key={child.path} className={`${styles.text} ${pathname === child.path ? styles.active : ''}`} onClick={() => navigate(child.path)}>
          <p>{child.name}</p>
        </p>
      )) : ''
    }
  </button>)


  return (
    <>
      <GiHamburgerMenu className={styles.burger} onClick={runShow} />
      <div className={styles.sidebar} style={{ left: reduce ? (show ? '0' : '-300px') : '0' }}>
        <Component left={'300px'} />
      </div>
      {(reduce && show) && <div className={styles.clickaway} onClick={() => setShow(false)}>

      </div>}
    </>
  )
}

export default Sidebar