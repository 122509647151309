import React from 'react'
import { Input } from '../../../Components'
import Form from '../../../Components/Form'
import { Box } from '@mui/material'
import { useMe } from '../../../hooks'
const EditProfile = () => {


    const me  = useMe()


    return (
        <Form method={'post'} url={`auth/profile/update/${me?.id}`} btnText={'Save'} >
            <div style={{ display: 'none' }}>
                <Input label='First name' placeholder='Eg. John' name='id' type='hidden' required defaultValue={me?.id} /></div>

            <Box display={'flex'} gap={'16px'}>
                <Input label='First name' placeholder='Eg. John' name='first_name' required defaultValue={me?.first_name} />

                <Input label='Last name' placeholder='Eg. Doe' name={'last_name'} required defaultValue={me?.last_name} />
            </Box>
            <Input label='Email' placeholder='Eg. example@company.com' name={'email'} required defaultValue={me?.email} />
            <Input label='Phone number' placeholder='23 456 7890' type='tel' name={'phone'} required defaultValue={me?.phone} />
            <Input label='Position' placeholder='Eg. Manager' name={'job_position'} defaultValue={me?.job_position}
                required />
        </Form>
    )
}

export default EditProfile