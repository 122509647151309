import { useState } from 'react';
import { Table, Button, Form, Input, FlexText, Text } from "Components";
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { BiArrowBack } from 'react-icons/bi';
import { BsPlusLg } from 'react-icons/bs';
import { apiRequest } from 'Services';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { icons } from 'Assets/icons';
import { toast } from 'react-toastify';
import { formatPrice, selectOpts } from 'utils';
import uuid from 'react-uuid';
import styles from './index.module.scss'
import { Close } from '@mui/icons-material';

const ManualDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams()


    const { data: journal } = useQuery(`/admin/accounting/manual-journals/${id}`, { enabled: Boolean(id) })


    const [total, setTotal] = useState({ credit: 0, debit: 0 })
    const [tax, setTax] = useState({ credit: 0, debit: 0 })
    const [items, setItems] = useState([{
        id: new Date().getTime(),
        account_id: null, description: null, tax: null, amount: null
    }])

    // useEffect(()=>{setD})
    const deleteIem = (id) => { setItems(prev => prev.filter(x => x.id !== id)) }
    const Drops = ({ options, display, change = () => null, name, value }) => (
        <select onChange={change} name={name} defaultValue={value} >
            <option value="" className=''>Select {display}</option>
            {options?.map(prop => <option key={prop.value} value={prop?.value}>{prop?.label}</option>)}
        </select>
    )

    // const columns = [
    //     { key: 'account', name: 'Account', render: (data, i) => <Drops name={`account_id`} change={(e) => handleItemChange(e, i)} display={'account'} value={data?.account_id} options={selectOpts(accounts?.data)} /> },
    //     { key: 'description', name: 'Description', render: (data, i) => <input type='text' name='description' min={0} onChange={(e) => handleItemChange(e, i)} defaultValue={items[i]?.description} /> },
    //     { key: 'tax', name: 'Tax', render: (data, i) => <input type='number' name='tax' min={0} onChange={(e) => handleItemChange(e, i)} defaultValue={items[i]?.tax} /> },
    //     { key: 'debit', name: 'Debit', render: (data, i) => <input type='number' name='debit' min={0} onChange={(e) => hanldeAmount(e, i)} defaultValue={items[i]?.debit} /> },
    //     { key: 'credit', name: 'Credit', render: (data, i) => <input type='number' name='credit' min={0} onChange={(e) => hanldeAmount(e, i)} defaultValue={items[i]?.credit} /> },
    //     { key: 'credit', name: '', render: (data, i) => <Close onClick={() => deleteIem(data.id)} /> },
    // ]

    const columns = [
        { name: 'Accounts', key: 'account.id', render: data => data?.account?.name + " - " + data?.account?.code },
        { name: 'Description', key: 'description' },
        { name: 'Tax', key: 'tax' },
        { name: 'Credit', key: 'amount', render: (data) => data.type === 'credit' ? data.amount : 0 },
        { name: 'Debit', key: 'amount', render: (data) => data.type === 'debit' ? data.amount : 0 },
        // { name: 'Type', key: 'type' },
    ]
    const [formValues, setFormValues] = useState({

    })
    const handleFormChange = (e) => setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))


    const journal_id = uuid().replaceAll('-', '').substring(8, 16)
    const submit = async () => {
        if (Math.abs((total.credit + tax.credit) - (total.debit + tax.debit)) !== 0) {
            toast.info('Credit and Debit has to be equal')
        } else {
            let newItems = items.map(x => {
                let itemsWithoutId = x
                delete itemsWithoutId.id
                return itemsWithoutId
            })



            try {
                await apiRequest({
                    method: "post", url: '/admin/accounting/manual-journals', hasAuth: true, payload: {
                        ...formValues,
                        tax,
                        journal_id,
                        period: dayjs(formValues?.period).format('MM-YYYY'),
                        date: dayjs(formValues?.date).format('YYYY-MM-DD'),
                        difference: Math.abs((total.credit + tax.credit) - (total.debit + tax.debit)),
                        total: { credit: total.credit + tax.credit, debit: total.debit + tax.debit },
                        subtotal: total,
                        entries: newItems
                    }
                })
                toast.success('Journal created successfully')
                navigate('/accounting/manual-journal/')
            } catch (error) {

            }
        }
    }
    const boxer = 'flex space-between w-full';

    const handleAdd = () => {
        setItems(prev => {
            let newList = prev;
            newList = [...newList, {
                id: new Date().getTime(),
                account_id: null, description: null, tax: null, amount: null, type: null
            }]
            return newList
        })
    }
    return (
        <div className={styles.body}>
            <Box className='flex items-center border-b text-[1.5rem] py-4 w-full' >
                <button className='text-red-500' onClick={() => navigate(-1)}><BiArrowBack /></button> &nbsp; <p className='font-bold'>{!id && 'Add'} Manual  Journal {id && 'Detail'} </p>
            </Box>
            <div className='pt-[12px] w-full pb-[24px]'>
                <Form className={'px-[24px]'}>
                    <Box className={`self-start items-center my-3 lg:w-[100%] w-full`}>
                        <Box className={`${boxer} gap-4`}>
                            <Input label='Date' placeholder={journal?.data?.date} name='date' onChange={handleFormChange} />
                            <Input label='Journal ID' placeholder={journal?.data?.journal_id} name='journal_id' type='text' disabled />

                            <Input label='Currency' placeholder='₦' disabled name='period' type='number' onChange={handleFormChange} />
                            <Input label='Description' placeholder={journal?.data?.description} disabled name='description' type='text' onChange={handleFormChange} />
                        </Box>
                    </Box>
                </Form>

                <Table
                    column={columns}
                    action={false}

                    data={journal?.data?.entries}
                />
                {!id && <Box className={`${boxer} justify-center my-2`}>
                    <Button radius='24px' bgcolor='#f2f2f2' color='#232323' w='auto' onClick={handleAdd}><BsPlusLg /> Add another line</Button>
                </Box>}
                <Box className=' ms-0 w-full px-[24px] mt-2 '>
                    <Box className={`bg-[#F2F2F2] rounded py-3 pl-4 pr-5  flex flex-col gap-4 w-[100%]`}>
                        <div className='flex justify-between w-full'>
                            <div className='!w-[50%] '>
                                SubTotal
                            </div>
                            <div className='!w-[25%] flex justify-between'>
                                <div><Text >{formatPrice(journal?.data?.debit_subtotal)}</Text></div>
                                <div><Text>{formatPrice(journal?.data?.credit_subtotal)}</Text></div>
                            </div>
                        </div>
                        <div className='flex justify-between w-full'>
                            <div className='!w-[50%] '>
                                <Text weight='700'>Total</Text>
                            </div>
                            <div className='!w-[25%] flex justify-between'>
                                <div><Text weight='700' >{formatPrice(journal?.data?.debit_total + journal?.data?.debit_tax)}</Text></div>
                                <div><Text weight='700'>{formatPrice(journal?.data?.credit_total + journal?.data?.credit_tax)}</Text></div>
                            </div>
                        </div>
                        <FlexText leftText='Difference' leftColor='#2463FF' rightColor='#2463FF' rightText={formatPrice(journal?.data?.difference)} />
                    </Box>
                </Box>
                {!id && <div className='w-full flex px-[24px]'>
                    <Button w='200px' radius='200px' className={'justify-right mt-3'} onClick={submit}>Save</Button>
                </div>}
            </div>
        </div>
    );
};

export default ManualDetails;
