import React from "react";
import styles from "./button.module.scss";
import { Text } from "../Text";
import Loader from "../Loader";

export const Button = ({
  bgcolor = "#B8541B",
  color = "white",
  radius = "8px",
  children,
  shadow = true,
  textSize = "16px",
  w = "100%",
  onClick = () => null,
  disabled = false,
  border,
  style,
  type = "button",
  loading = false,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.btn} ${
        disabled ? styles.disabled : ""
      }  ${className}`}
      disabled={disabled}
      type={type}
      style={{
        backgroundColor: bgcolor,
        color,
        borderRadius: radius,
        fontSize: textSize,
        width: w,
        border,
        textAlign: "center",
        whiteSpace: "nowrap",

        ...style,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <Text
          className="!whitespace-nowrap"
          color={color}
          align=""
          width="fit-content"
          weight="500"
          size="14px"
          isLink
        >
          {children}
        </Text>
      )}
    </button>
  );
};
