import React from 'react'
import PurchaseOrder from '../PurchaseOrder'
import { useParams } from 'react-router'
import ApproveExpense from './ApproveExpense'
import ApproveInvTransferDetails from '../Inventory/components/ApproveInvTransferDetails'
import OverDraftApproval from './Overdraft'
import ManualStockApproval from './ManualStockAdj'

const Approve = () => {
  const { type, id } = useParams()

  return (
    <>
      {type.includes('purchase') && <PurchaseOrder />}
      {type.includes('expense') && <ApproveExpense />}
      {type.includes('inventory_transfer') && <ApproveInvTransferDetails />}
      {type.includes('overdraft') && <OverDraftApproval />}
      {type.includes('manual_stock_adjustment') && <ManualStockApproval />}
    </>
  )
}

export default Approve