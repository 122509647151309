import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

const initialState = {
    name: '',
    price: 0,
    items: [
        { menu_id: '', quantity: '' }
    ]
}

export const packageItem = createSlice({
    name: 'packageItem',
    initialState,
    reducers: {
        setInitialPackage: (state, { payload }) => {
            console.log(payload)
            state.name = payload?.name
            state.price = payload?.price;
            state.branch_id = payload?.branch?.id
            // state.items = payload?.items
            Object.values(payload?.items || {})?.map(({ id, quantity }, i) => {
                state.items[i] = { id, quantity }
            })
        },
        setItem: (state, { payload: { name, value } }) => {
            state[name] = value
        },
        setPackageItem: (state, { payload: { name, value, i } }) => {
            state.items[i] = { ...state.items[i], [name]: value }
        },
        addItem: (state, { payload }) => {
            state.items = [...state.items, { menu_id: '', quantity: '' }]
        },
        removeIngredient: (state, { payload: { index } }) => {
            if (state.items.length !== 1) {
                state.items = (state.items.filter((item, i) => i !== index))
            } else {
                toast.info('Package  must have an item')
            }

        },
        clearPackage: (state) => {
            state.items = [{ menu_id: '', quantity: '' }]
            state.name = ''
            state.price = 0
        }
    },
})

// Action creators are generated for each case reducer function
export const { setItem, setPackageItem, addItem, removeIngredient, setInitialPackage, clearPackage } = packageItem.actions

export default packageItem.reducer