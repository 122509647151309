import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Box } from "@mui/material";

const VerticalProgressStep = ({ current = 0, points = [], onChange = () => null, next = () => null, clickable = false }) => {
    const Next = ({ active, first, icon, onClick = () => null, done }) => (
        <>
            <Box onClick={onClick} className={styles.next}>
                {!first && <Box className={`${styles.verticalLine} `} />}
                <Box className={`${styles.step} ${active ? styles.active : ' '}  ${done ? styles.done : ' '}`} children={icon} />
                <Box>

                </Box>
            </Box>

        </>
    )
    const [point, setPoint] = useState(current)
    useEffect(() => {
        onChange(point)
    }, [point, onChange])
    useEffect(() => {
        setPoint(current)
    }, [current])
    return (
        <Box component={'div'} className={styles.vContainer}>
            {points.map((x, i) => (
                <div className={styles.stepContainer}>
                    <Next
                        first={i === 0}
                        active={point > i}
                        done={point > i}
                        content={x?.content ? x?.content : i + 1}
                        icon={x?.icon ? x?.icon : i + 1}

                        onClick={() => { if (clickable) setPoint(i) }}
                    />
                    <Box className={styles?.content}>
                        {x?.content}
                    </Box>

                </div>

            ))}
        </Box>
    );
};

export default VerticalProgressStep;
