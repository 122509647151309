import { toast } from "react-toastify";
import { store } from "store"
import { logout } from "store/slices/userSlice";

let timer, interval, time;

export const autoLogout = () => {

    // console.log(store.getState()?.user?.isLoggedIn)
    if (store.getState()?.user?.isLoggedIn) {

        time = 3600000
//1hour
        timer = setTimeout(() => {
            // console.log('first')
            // clearInterval(interval)
            toast.info('You log in session expired')
            clearTimeout(timer)
            store.dispatch(logout())
        }, time);
        // interval = setInterval(() => {
        //     console.log(i++)
        // }, 1000);
    } else {
        clearInterval(interval)
        clearTimeout(timer)
    }
}



export const check_session = (renew) => {
    clearTimeout(timer)
    // clearInterval(interval)
    autoLogout()
}