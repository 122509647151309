import React from "react";
import styles from "./text.module.scss";
function Text({
  children,
  weight = "400",
  color = "#000000",
  size = "16px",
  lHeight = "24px",
  gap = "10px",
  icon = true,
  isLink = false,
  onClick = () => null,
  className,
  style,
  width = "fit-content",
  align = "center",
  ...props
}) {
  return (
    <p
      style={{
        fontWeight: weight,
        color,
        fontSize: size,
        lineHeight: lHeight,
        gap,
        textAlign: align,
        margin: 0,
        width,
        cursor: isLink ? "pointer" : "auto",
        ...styles,
      }}
      onClick={onClick}
      {...props}
      className={`${styles.textstyles} ${className}`}
    >
      {icon}
      {children}
    </p>
  );
}

export { Text };
