import React, { useEffect, useMemo, useState } from 'react';
import styles from './Invoivce.module.scss';
import { Header } from 'Partial/Header';
import { Button, FlexText, Form, Input, Table } from 'Components';
import { icons } from 'Assets/icons';
import { BiPlus } from 'react-icons/bi';
import { apiRequest } from 'Services';
import { useQuery } from 'react-query';
import { selectOpts } from 'utils';
import { formatPrice } from 'utils/formatPrice';
import { Close } from '@mui/icons-material';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

const CreateInvoice = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const [entries, setEntries] = useState([])
    const { data: categories } = useQuery(`/admin/service-price-lists/categories`)
    // const status = [{ id: 'Paid', name: 'Paid' }, { id: 'Unpaid', name: 'Unpaid' }]
    const { data: items } = useQuery('/admin/service-price-lists')
    const invoice_id = new URLSearchParams(location.search).get("invoice_id")
    const { data: deets } = useQuery(`/shop/secure/invoices/${invoice_id}`, { enabled: Boolean(invoice_id) })
    const [sub_total, set_Sub__Total] = useState(0)
    const [totalTax, setTotalTax] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    // /shop/secure/bookings/:bookingType/:bookingId/mark-as-paid
    const addEntry = () => {
        const id = Date.now()
        setEntries(prev => [...prev, {
            id,
            "service_id": null,
            "quantity": null,
            "unit_price": null,
            "tax": 0,
            "discount": 0, "total": 0,
        }])

    }

    useEffect(() => {
        const defaultItems = deets?.data?.items?.map(item => {
            const _item = (items?.data?.find(deetItem => deetItem?.name === item?.name))
            // console.log(item )
            return {
                "id": item?.id,
                "service_id": _item?.id,
                "quantity": item?.quantity,
                "unit_price": item?.rate,
                "tax": item?.tax,
                "total": item?.quantity * item?.rate,

            }
        })

        if (deets?.data?.items?.length) {
            setEntries(defaultItems)
            set_Sub__Total((defaultItems?.map(item => item?.unit_price) || [])?.reduce((a, b) => a + b))
            setTotalTax((defaultItems?.map(item => item?.tax) || [])?.reduce((a, b) => a + b))
            setTotalDiscount((defaultItems?.map(item => item?.discount) || [])?.reduce((a, b) => a + b))
        }

    }, [deets])

    const details = useMemo(() => {
        const booking_id = new URLSearchParams(location.search).get("booking_id")
        const category = new URLSearchParams(location.search).get("category")

        const price_editable = category === 'Media Service'
        return {
            booking_id,
            category, price_editable
        }
    }, [location.search])

    const deleteEntry = (id) => setEntries(prev => prev.filter(x => x.id !== id))
    const editEntry = (i, e) => {
        const name = (e.target.name)
        const value = parseInt(e.target.value)

        const edit = (name, value) => {
            setEntries(prev => {
                let edited = prev
                edited[i] = { ...edited[i], [name]: value }
                return edited
            })
        }

        if (name === 'service_id') {
            const res = items?.data?.find(item => item?.id === value)
            edit(e.target.name, e.target.value)
            edit("tax", res?.tax_rate)
            if (!details.price_editable) edit("unit_price", res?.price)
        } else { edit(e.target.name, e.target.value) }
        if (entries[i]?.quantity && entries[i]?.unit_price && entries[i]?.tax >= 0) {
            edit("total", (entries[i]?.unit_price * parseInt(entries[i]?.quantity)))
            console.log(entries[i])
            // + entries[i]?.tax
            // const quantity_list = entries.map(entry => parseInt(entry.quantity))
            const total_list = entries.map(entry => parseInt(entry.total))
            const tax_list = entries.map(entry => parseFloat(entry.tax))
            const discount_list = entries.map(entry => parseFloat(entry.discount))
            if (total_list.length) set_Sub__Total(total_list.reduce((a, b) => a + b))
            if (tax_list.length) {
                setTotalTax(tax_list.map((tax, i) => {
                    return (tax / 100) * total_list[i]
                }
                ).reduce((a, b) => a + b))
            }
            if (discount_list.length) {
                // console.log(discount_list)
                const res = (discount_list.map((discount, i) => discount || 0).reduce((a, b) => a + b))
               setTotalDiscount(res)
            }
        }



    }
    const [formValues, setFormValues] = useState({})
    const handleChange = (e) => setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))

    const save = async () => {

        try {
            await apiRequest({
                method: 'post', payload: {
                    "booking_id": details.booking_id,  // not needed in accounting
                    // "date": formatDate({ date: formValues?.date, type: 'backenddate' }),
                    "date": dayjs(formValues?.date).format('YYYY-MM-DD'),
                    "category": formValues?.category?.replace(' ', '-') || details.category?.replace(' ', '-'),
                    "status": 'Unpaid',
                    "discount": 0,
                    "subtotal": sub_total,
                    "total": parseFloat((sub_total + totalTax - totalDiscount).toFixed(2)),
                    items: entries
                }, url: '/shop/secure/invoices', hasAuth: true
            })
            toast.success('Invoice created successfully')
            navigate(-1)
        } catch (error) {
            console.log(error)
        }
    }
    const column = useMemo(() => ([
        {
            name: 'Item details', key: 'service_id', render: (data, i) => <select name={'service_id'} onChange={(e) => editEntry(i, e)} defaultValue={data?.service_id}>
                <option value="">Select item</option>  {items?.data?.filter(x => x.category === details.category)?.map(x => <option value={x?.id}>{x?.name}</option>)}
            </select>
        },
        { name: 'Quantity', key: 'quantity', render: (data, i) => <input type='number' name={'quantity'} defaultValue={data?.quantity} onChange={(e) => editEntry(i, e)} /> },
        { name: 'Unit price', key: 'unit_price', render: (data, i) => <input type='number' name={'unit_price'} disabled={!details.price_editable} defaultValue={data?.unit_price} onChange={(e) => editEntry(i, e)} /> },
        { name: 'Discount', key: 'discount', render: (data, i) => <input type='number' name={'discount'} onChange={(e) => editEntry(i, e)} defaultValue={data?.discount} /> },
        { name: 'Tax', key: 'tax', },
        { name: 'Total', key: 'total' },
        { name: 'Action', key: 'action', render: (data, i) => <Close className='cursor-pointer' onClick={() => deleteEntry(data?.id)} /> },
    ]), [items, entries])

    const Head = () => (
        <div className={styles.header}>
            <div className='w-[50%] flex flex-col gap-4'>
                <Form>
                    <Input label='Booking Number' name={'booking_id'} placeholder={details.booking_id} disabled />
                    {/* {details.category} */}
                    <Input type='select' label='Category' name={'category'} placeholder={'Select category'} onChange={handleChange} defaultValue={formValues?.category || details.category} disabled={Boolean(details.category)} option={selectOpts(categories?.data?.map(({ name }) => ({ name, id: name })))} />
                </Form>
            </div>
            <div className='w-[50%] flex flex-col gap-4'>
                <Form>
                    {/* <Input type='select' label='Status' placeholder={'Select status'} onChange={handleChange} name={'status'} value={formValues?.status} option={selectOpts(status)} /> */}
                    <Input type='date' label='Invoice Date' placeholder={'Enter invoice date'} onChange={handleChange} name={'date'} defaultValue={formValues?.date} />
                </Form>
            </div>
        </div>
    )

    const Footer = () => (
        <div className={styles.footer}>
            <FlexText leftText='Subtotal' rightText={'₦' + sub_total} />
            <FlexText leftText='Discount' rightText={'₦' + totalDiscount} />
            <FlexText leftText='Tax' rightText={formatPrice(totalTax)} />
            <FlexText leftWeight={'600'} leftText='Total' rightText={formatPrice(sub_total + totalTax - totalDiscount)} rightWeight={'600'} />
        </div>
    )
    return (
        <div className={styles.body}>

            <Header
                name='Create New Invoice'
                text={<>Save</>}
                buttonOnclick={save}
            />

            <div className={styles.container}>
                <div className={styles.left}>
                    {<Head />}
                    <Table
                        column={column}
                        data={entries}

                        action={false}
                        actions={(data) => (
                            <div>
                                <Button bgcolor='inherit ' shadow={false} color='#EB2F2F' onClick={() => deleteEntry(data?.id)}>  {icons.delete} Delete</Button>
                            </div>
                        )}
                    />
                    <div className='flex w-full justify-center my-5 '>
                        <div className='rounded-[100px] font-bold cursor-pointer flex gap-4 py-2 px-5 items-center bg-[#F7F7F7]' onClick={addEntry}>
                            <BiPlus fontWeight={600} /> Add Another Line
                        </div>
                    </div>
                    {<Footer />}
                </div>
            </div>
        </div>
    );
};

export default CreateInvoice;
