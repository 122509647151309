import { createSlice } from '@reduxjs/toolkit'
import { v4 } from "uuid";
const initialState = {
    search: '',
    sort: '',

}

export const listSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        searchList: (state, { payload }) => {
            // console.log(payload)
            state.search = payload
        },
        sort_List: (state, { payload: { i, name, value } }) => {

            state.list[i] = { ...state.list[i], [name]: parseInt(value) }
        },
        downloadList: (state, { payload }) => {
            
            state.list = (state.list.filter((list, i) => list.id !== payload))

        },
    },
})

// Action creators are generated for each case reducer function
export const { searchList, sort_List, downloadList } = listSlice.actions

export default listSlice.reducer