import { BtnStack, Text } from "Components"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { closeModal } from "store/slices/modalSlice"
import { formatDate } from "utils/formatPrice"

export const NearestAvailableList = ({ children, name, list, onAvailable = () => null, back = () => null, selectMessage }) => {
    // useEffect(() => { dispatch(changeTitle('Book Short-lets')); setList(list?.data) }, [])
    const dispatch = useDispatch()
    return (
        <>
            {

                list?.data?.length ? <div className='flex-col flex w-full !gap-[12px]'>
                    {children}
                    <BtnStack leftClick={back} rightClick={onAvailable} rightText='Proceed' />
                </div>
                    : <div> No available {name} around  <br />
                        <BtnStack leftClick={back} rightClick={() => dispatch(closeModal())} rightText='End' /></div>
            }
        </>
    )
}
