import React, { useEffect, useMemo, useState } from "react";
import { Header } from "Partial/Header";
import { FlexText, Table, TopButtons, Text } from "Components";
// import { columns, requestedColumns, preparingColumns, readyColumns, completedColumns } from './utils';
import { Box } from "@mui/material";
import { BsArrowLeft, BsFillBellFill } from "react-icons/bs";
import VerticalProgressStep from "Components/ProgressStep/VerticalProgress";
import { AiTwotoneDollarCircle } from "react-icons/ai";
// import { ImCheckmark } from 'react-icons/im';
import styles from "./Orders.module.scss";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { apiRequest } from "Services";
import { formatPrice } from "utils";
import { formatDate } from "utils/formatPrice";
import { Check } from "@mui/icons-material";
import { openModal } from "store/slices/modalSlice";
import { useDispatch } from "react-redux";
import DownloadOrders from "./DownloadOrders";
import Status from "Components/Status";
import { BsChevronRight } from "react-icons/bs";
// import { Link } from "react-router-dom"

const Orders = () => {
  const { id } = useParams();
  const [detail, setDetail] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const getid = await apiRequest({
        url: `/shop/secure/orders/${id}`,
        hasAuth: true,
      });
      setDetail(getid?.data?.data);
    };
    if (id) getData();
  }, [id]);

  const columns = [
    { key: "created_at", name: "Date" },
    { key: "order_id", name: "Transaction ID" },
    {
      key: "branch.name",
      render: (data) => data?.branch?.name,
      name: "Branch",
    },

    {
      key: "customer",
      name: "Customer",
      render: (data) => data?.customer?.full_name,
    },
    {
      key: "venue.name",
      name: "Venue",
      render: (data) => data?.venue?.name || "No venue",
    },
    {
      key: "table?.unique_id",
      name: "Table",
      render: (data) => data?.table?.name || "No table",
    },
    {
      key: "total",
      name: "Price",
      render: (data) => `₦${data?.total?.toLocaleString()}`,
    },
    { key: "order_type", name: "Order Type" },
    { key: "payment_method", name: "Payment Method" },
    {
      key: "status",
      name: "Status",
      render: (data) => <Status data={data}>{data?.status}</Status>,
    },
    {
      key: "created_by",
      name: "Created By",
      render: (data) => data.creator.fullname || "Customer",
    },
    {
      key: "status",
      name: "Action",
      render: (data) => (
        <Link to={`/operations/orders/${data?.id}`}>
          <BsChevronRight />
        </Link>
      ),
    },
  ];

  const [currentTable, setCurrentTable] = useState({
    column: columns,
    data: [],
    key: "",
  });

  const current = useMemo(() => {
    const list = detail?.tracking_status;
    if (list?.length) {
      const index = list?.map((item) => item?.date)?.indexOf(null);
      // console.log(first)
      if (index === -1) return detail?.tracking_status?.length;
      return index;
    }
    return detail?.tracking_status?.length;
  }, [detail?.tracking_status]);

  const Head = () => (
    <div className={styles.header}>
      <FlexText leftText="Order ID" rightText={detail?.order_id} />
      <FlexText
        leftText="Date"
        rightText={detail?.created_at && formatDate(detail?.created_at)}
      />
      <FlexText leftText="Status" rightText={detail?.status} />
    </div>
  );

  const Footer = () => (
    <div className={styles.footer}>
      <FlexText leftText="Discount" rightText={formatPrice(detail?.discount)} />
      <FlexText leftText="Subtotal" rightText={formatPrice(detail?.subtotal)} />
      <FlexText
        leftText="Delivery fee"
        rightText={formatPrice(detail?.shipping_fee)}
      />
      {/* <FlexText leftText='Charges' /> */}
      <FlexText leftText="Total" rightText={formatPrice(detail?.total)} />
    </div>
  );
  const Right = () => (
    <div className={styles.right}>
      <header className={styles.rightHeader}>
        {/* {icons.bell} */}
        <BsFillBellFill />
        <Text weight="600" lHeight="24px" size="16px">
          Tracking status
        </Text>
      </header>
      <div className={styles.rightBody}>
        {detail?.tracking_status?.length && (
          <VerticalProgressStep
            current={current}
            points={[
              ...detail?.tracking_status?.map((track) => ({
                content: (
                  <>
                    {track?.status}
                    <br />
                    {track?.date}
                  </>
                ),
                icon: track?.date ? (
                  <Check color="red" />
                ) : (
                  <AiTwotoneDollarCircle fill="white" />
                ),
              })),
            ]}
          />
        )}
        {/* {detail?.tracking_status && <VerticalProgressStep points={[

          ...Object.values(detail?.tracking_status)?.map(status => ({
            icon: status?.date ? <AiTwotoneDollarCircle fill='white' /> : <ImCheckmark />, content: <Box>
              <Text size='16px' weight='400' lHeight='24px'>{status.status}</Text>
              <Text weight='600' size='12px' lHeight='18px'>{status?.date}</Text>
            </Box>
          }))

        ]} />} */}
      </div>
    </div>
  );
  return (
    <>
      <Header
        export_name={"orders"}
        onExport={() =>
          dispatch(
            openModal({ title: "Download Order", content: <DownloadOrders /> })
          )
        }
        name={
          <>
            {id && (
              <Link to="/operations/orders" style={{ color: "#B8541B" }}>
                {" "}
                <BsArrowLeft />
              </Link>
            )}
            Orders {id && "Detail"}
          </>
        }
        // text={id ? '' : <><BsPlus /> Create purchase order</>}
        // buttonOnclick={() => dispatch(openModal({ title: 'Add purchase order', content: <AddPurchaseOrder /> }))}
      >
        {!id && (
          <Box width={"100%"}>
            <TopButtons
              btns={[
                {
                  text: "All",
                  onClick: () => setCurrentTable({ key: "" }),
                  key: "all",
                },
                {
                  text: "Requested",
                  onClick: () =>
                    setCurrentTable({ key: "Waiting Confirmation" }),
                  key: "requested",
                },

                {
                  text: "Pending",
                  onClick: () => setCurrentTable({ key: "Pending" }),
                  key: "Pending",
                },

                {
                  text: "Preparing",
                  onClick: () => setCurrentTable({ key: "Preparing" }),
                  key: "Preparing",
                },

                {
                  text: "Ready",
                  onClick: () => setCurrentTable({ key: "Ready" }),
                  key: "Ready",
                },

                {
                  text: "Completed",
                  onClick: () => setCurrentTable({ key: "Completed" }),
                  key: "completed",
                },
              ]}
            />
          </Box>
        )}
      </Header>

      <div className={styles.body}>
        <div className={styles.container}>
          <div className={styles.left}>
            {id && <Head />}

            {!id && (
              <Table
                appendQuery
                column={columns}
                // data={tableData}
                url={`/shop/secure/orders${
                  currentTable.key ? `?status=${currentTable.key}` : ""
                }`}
                // actionIcon={<BsChevronRight />}
                action={false}
              />
            )}
            {id && (
              <Box className={styles.items}>
                {detail?.items?.map((x) => (
                  <div key={x?.id} className={styles.item}>
                    <div>
                      <img src="" alt="" />
                      <Text>{x?.menu?.name}</Text>
                    </div>
                    <div>
                      <Text weight="600">x{x?.quantity}</Text>
                      <Text weight="600"> {formatPrice(x?.price)}</Text>
                    </div>
                  </div>
                ))}
              </Box>
            )}
            {id && <Footer />}
          </div>
          {id && <Right />}
        </div>
      </div>
    </>
  );
};

export default Orders;
