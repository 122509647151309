import React, { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from './table.module.scss'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { ActionModal } from '../ActionModal';
import { useSelector } from 'react-redux';
import { apiRequest } from 'Services';
import { Pagination, Tooltip } from '@mui/material';
import { useLocation, useNavigate, } from 'react-router';
import { searchList } from 'store/slices/listSlice';
import { useDispatch } from 'react-redux';



function BeMediaTable({
    numbers = true, //boolean
    column = [], //array
    data = null, // array
    action = true, // boolean
    actions = () => null,// node / element
    emptyState = 'This table is empty',
    url = '',
    onSuccess = () => null,
    actionIcon,
    useRandomkey = false, appendQuery = false, refetch,
    defaultRowPerpage = 10, noPagination = false,
    fn = (data) => data,
    update,
    getPageCount = (data) => null
}) {
    const { pathname, search } = useLocation('')
    const [showAction, setShowAction] = useState(false)
    const [state, setState] = useState(null)
    const [tableData, setTableData] = useState(data)
    const [, setHoveringIndex] = useState(null)
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { search: searchValue } = useSelector(state => state.list)
    const { isOpen } = useSelector(state => state.modal)
    const dispatch = useDispatch()

    const paginationType = useMemo(() => {

        if (tableData?.length) {
            return 'frontend'
        } else {
            return 'backend'
        }
    }, [tableData])

    const updatedUrl = useMemo(() => {
        // if (searchValue) return appendQuery ? `${url}&search=${searchValue}` : `${url}?search=${searchValue}`
        const link = new URLSearchParams(search)
        if (paginationType === 'backend') {
            // console.log()
            if (link.get('page') === 0) {
                console.log(link.get('page'))
                link.set('page', 1)
            }
        }

        let newUrl = url
        if (url.indexOf('?') > 0) {
            const extra = url.substring(url.indexOf('?'), url.length)
            newUrl = newUrl.replace(extra, '')
            const queries = Object.fromEntries(new URLSearchParams(extra))
            Object.entries(queries).forEach(element => {
                link.set(element[0], element[1])
                // console.log(element[0], element[1])
            })
        }
        if (searchValue) link.append('search', searchValue)

        const res = `${newUrl}?${link.toString()}`
        // console.log(res)
        return res
    }, [url, searchValue, search, paginationType])
    useEffect(() => {
        dispatch(
            searchList('')
        )

    }, [pathname, dispatch])

    const [rowsPerPage, setRowsPerPage] = useState(defaultRowPerpage)



    useEffect(() => {
        const fetchData = async () => {
            console.log({updatedUrl})
            try {

                const { data } = await apiRequest({ url: updatedUrl, hasAuth: true })
                onSuccess(data?.data)
                setTableData(fn(data?.data))

            } catch (error) {
                // console.log("Table error => ", error)
            }
        }
        if (url) {
            fetchData()
        } else {
            onSuccess(data)
            let temp = fn(data)
            // console.log(temp)
            setTableData(temp)
            // console.log(temp?.slice(indicies.start, indicies.end))
            // setTableRange(temp?.slice(indicies.start, indicies.end))
            // setPageLength(data?.length)

        }

        // eslint-disable-next-line
    }, [url, data, isOpen, updatedUrl, refetch, update])



    const [indicies, setIndicies] = useState({ start: 0, end: 10 })


    const paginatedTableData = useMemo(() => {
        const isFrontendPaginated = paginationType === 'frontend'

        if (!isFrontendPaginated) return { data: tableData?.data || tableData?.items, count: tableData?.total || tableData?.count }
        if (tableData?.length) {
            if (isFrontendPaginated) {

                return { data: tableData, count: tableData?.length }
            }
        }
    }, [tableData, paginationType])

    // const paginatedTableData = useMemo(()=>{})
    // console.log(paginatedTableData)
    useEffect(() => {
        if (paginatedTableData.count >= 1) {
            // console.log(paginatedTableData[0])
            setState('filled')
        } else if (paginatedTableData.count === 0) {
            setState('empty')
        } else if (paginatedTableData === undefined) {
            setState(null)
        }
    }, [paginatedTableData])

    useEffect(() => {

        setIndicies(prev => ({ start: (page - 1) * rowsPerPage, end: ((page - 1) * rowsPerPage) + rowsPerPage, }))
    }, [page, navigate, pathname, tableData?.length, rowsPerPage])

    const CellValue = ({ string, max = 40 }) => {
        if (String(string).length > max && column.length > 2) {
            return (
                <>
                    <Tooltip title={string} arrow className=' ' >
                        <span>  {String(string).substring(0, max - 1) + '...'}</span>
                    </Tooltip>
                </>)
        } else { return string }

    }
    useEffect(() => {
        const deets = new URLSearchParams(search)
        const currPage = parseInt(deets.get('page'))
        const currLimit = parseInt(deets.get('limit'))
        deets.set('page', currPage || 1)
        deets.set('limit', currLimit || 10)
        console.log(deets.toString(), currLimit)
        navigate(`${pathname}?${deets}`)
        setPage(currPage || 0)
    }, [search, pathname, navigate])
    const changePage = (param) => {

        const deets = new URLSearchParams(search)

        const currLimit = parseInt(deets.get('limit')) || 10
     
        const path = (`${pathname}?page=${param}&limit=${currLimit}`)
        navigate(path)
        // setPage(param)

    }
    const changeRowsPerrpage = (param) => {

        setRowsPerPage(param)
        const deets = new URLSearchParams(search)
        deets.set('limit', param)
        console.log(deets.toString())
        navigate(`${pathname}?${deets.toString()}`)
    }
    const pgCnt = useMemo(() => {
        const count = getPageCount(tableData) || paginatedTableData?.count
        const res = Math.floor(count / rowsPerPage)
        // const resfloor = Math.floor(count / rowsPerPage) 
        // console.log({ resBeforeFloor: count / rowsPerPage, res, ...paginatedTableData, rowsPerPage })
        const isleft = (count % rowsPerPage)
        // console.log(isleft)
        return res + (isleft ? 1 : 0)

    }, [paginatedTableData, rowsPerPage,tableData,getPageCount])
    return (
        <div className=''>
            <TableContainer component={'div'} className={`${styles.container}`}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead component={'thead'}>
                        <TableRow
                            component={'tr'} sx={{ height: '50px' }}>
                            {numbers && <TableCell component={'td'} align='left' className={styles.head}>#</TableCell>}
                            {column.map(({ name, style }) => <TableCell component={'td'} align='left' className={styles.head} sx={{ ...style }}>{name}</TableCell>)}
                            {action && <TableCell component={'td'} align='left' className={styles.head}>action</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody component={'tbody'} className=' relative' sx={{ width: '100%', height: '100%' }}>
                        {state === 'filled' &&
                            ((paginationType === "frontend" ? paginatedTableData.data?.slice(indicies.start, indicies.end) : paginatedTableData.data)?.map((row, i) => <TableRow
                                onMouseOver={() => setHoveringIndex(i)}
                                onMouseLeave={() => setHoveringIndex(null)}
                                component={'tr'}
                                key={useRandomkey ? Math.random() : row?.id} className={styles.row}
                                sx={{ borderBottom: '1px dashed #E2E2E2 !important', height: '64px' }}
                            >
                                {numbers && <TableCell component={'td'} align='left' className={styles.cell}>{
                                    (i + 1) + ((page - 1) * rowsPerPage)
                                }</TableCell>}
                                {
                                    column.map(({ key, render }) => (
                                        <TableCell component={'td'} align='left' key={key} className={styles.cell}>{render ? render(row, i) : <CellValue string={row[key]} />}</TableCell>
                                    ))
                                }
                                {
                                    action && (<TableCell component={'td'} align='center' className={`${styles.cell}`}>
                                        {<div className={styles.action} onClick={() => setShowAction(i)}>
                                            {actionIcon || <BsThreeDotsVertical />}
                                        </div>}
                                        {(showAction === i) && <ActionModal className={styles.goLow} onClickAway={() => setShowAction(false)} >
                                            {actions(row, i)}
                                        </ActionModal>}
                                    </TableCell>)
                                }
                            </TableRow>
                            ))}
                        {
                            (state === 'empty') && <div className='!w-full absolute !flex py-3 !h-[400px] items-center justify-center  !bg-success'>{emptyState}</div>
                        }
                        {/* {
                        (state === null) && <div className={styles.loading}> Loading...</div>
                    } */}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={styles.page} >

                {/* <Pagination count={Math.floor(tableData?.length / 10) + 1} defaultPage={page} npage onChange={(e, page) => setPage(page)} /> */}
                {(paginatedTableData.count) >= 10 &&
                    <Pagination
                        component="div"
                        sx={{ opacity: noPagination ? 0 : 1 }}
                        count={pgCnt}
                        page={page}
                        onChange={(e, page) => changePage(page)}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={(e) => changeRowsPerrpage(e.target.value)}
                    />
                    // <Pagination count={10} />
                }
            </div>
        </div>
    );
}
export { BeMediaTable }