import React from 'react'
import { ClipLoader } from 'react-spinners'

const Loader = ({ size = '30px' }) => {
    return (
        <ClipLoader color='#FFFFFF'/>

    )
}

export default Loader