import { Form, Input } from 'Components'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { downloadList } from 'utils'

const DownloadOrders = () => {
  const [dates, setDates] = useState({})

  const handleDates = (e) => {
    const index = e.target.name
    const date = dayjs(e.target.value).format('YYYY-MM-DD')
    setDates(prev => {
      let values = prev;
      values[index] = date;
      return values
    })
    console.log(dates)
    // handleChange('created_at[btw]', JSON.stringify(dates))
  }



  return (
    <Form btnText='Download' method={''} onSubmit={(res) => downloadList('orders', `created_at[btw]=${JSON.stringify([dates[0], dates[1]])}`)}>
      <Input type="date" onChange={handleDates} name='0' label='Date from' />
      <Input type="date" onChange={handleDates} name='1'  label='Date to'/>
      {/* <Input type="date" /> */}

    </Form>
  )
}

export default DownloadOrders