import { apiRequest } from "Services"
import dayjs from "dayjs"
import { toast } from "react-toastify"

export const cancelItem = async (order, item) => {

    try {
        await apiRequest({
            url: `shop/secure/orders/${order?.id}/items/${item?.id}`, payload: { status: 'Canceled' }, method: 'patch', hasAuth: true
        })
        // setUpdate(item.id)
        toast.success(`Item ${item?.menu?.name} has been cancelled`)
    } catch (error) {
        console.log(error)
    }

}
export const itemTimer = (order, item) => {
    const start = (order?.created_at)
    const duration = item?.duration
    const now = dayjs().format('YYYY-MM-DD hh:mmA')
    // console.log({ now, duration, start })

    let display = duration

    setInterval(() => {
        display--
        console.log({ display })
    }, 6000);
    return display + 'j'
}
export const getItems = (order) => {
    if (order?.items) return (order?.items)
    if (order?.suborders) return (order?.id, order?.suborders?.map(suborder => suborder?.items)?.flat(1))
}

export const removeCancelled = (items) => items?.length ? items?.filter(item => item?.status !== 'Canceled') : []