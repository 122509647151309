import { Text, Button } from "Components";
import React from "react";
import { formatDate } from "utils/formatPrice";
// import { useDispatch } from 'react-redux'
// import { changeTitle } from 'store/slices/modalSlice'

const Explore = ({
  startDate,
  onExplore = () => null,
  back = () => null,
  name,
}) => {
  // const dispatch = useDispatch()
  // useEffect(() => { dispatch(changeTitle('Book Short-lets')) }, [])
  return (
    <>
      <div className="flex-col flex w-full !gap-[12px]">
        <div className="w-full text-center ">
          <Text
            size="12px"
            align="center"
            width="100%"
            weight="400"
            color="#4F4F4F"
          >
            {formatDate({ type: "data", date: startDate })}
          </Text>
        </div>

        <Text className="" weight="400" size="14px">
          There are no {name} available at the moment in this time period.
          Explore nearest available {name}
        </Text>
        <Button radius="100px" onClick={onExplore}>
          Explore nearest available {name}
        </Button>
        <Button radius="100px" bgcolor="#EFEFEF" color="#B8541B" onClick={back}>
          Return home
        </Button>
      </div>
    </>
  );
};

export default Explore;
