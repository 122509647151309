import { toast } from "react-toastify";
import { ulid } from 'ulid'

export class Pay {
    constructor(amount, customer, desc) {
        this.amount = amount;
        this.reference = ulid().toLowerCase();
        this.customerFullName = customer?.full_name;
        this.email = customer?.email;
        this.desc = desc;
        this.errors = []
        // this.method = method;
    }

    pay = (method, complete) => {
        window.MonnifySDK.initialize({
            amount: this.amount,
            currency: "NGN",
            reference: this.reference,
            customerFullName: this.customerFullName,
            customerEmail: this.email,
            apiKey: "MK_TEST_DDUPH5X4ST",
            contractCode: "4331820114",
            paymentDescription: this.desc,
            // metadata: {
            //     "name": this.customerFullName,
            //     "age": 45
            // },
            paymentMethods: [
                method,
                // "CARD",
                // "ACCOUNT_TRANSFER",
                // "USSD",
                // "PHONE_NUMBER"
            ],
            //customers behive,card and bank transfer
            //pos/waiter/operations behive,card and bank transfer


            onLoadStart: () => {
                console.log("loading has started");
            },
            onLoadComplete: () => {
                console.log("SDK is UP");
            },
            onComplete:  function (response) {
                //Implement what happens when the transaction is completed.
                // toast
                const references = { transactionReference: response?.transactionReference, paymentReference: response?.paymentReference }
                 complete(references)
                // console.log(response, 'jhgfddfddd');

            },
            onClose: function (data) {
                //Implement what should happen when the modal is closed here

                // console.log(data?.responseMessage, 'jhgf');
                if (data?.responseMessage) toast.info(data?.responseMessage)
            },


        })
    }

    withBank(after) {
        this.checkDetails()

        if (!this.errors.length) this.pay('ACCOUNT_TRANSFER', after)
        return this
    }
    withCard(after) {
        // alert('kk')
        this.checkDetails()
        if (!this.errors.length) {
// alert('k')
            this.pay('CARD', after)
        }
        return this
    }

    checkDetails(fn) {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        // console.log(this.customerFullName, this.email)
        if (this.amount < 99) this.errors.push('Amount can not be less than N100');
        if (!this.email?.match(validRegex)) this.errors.push('A valid email is required for this payment method');
        if (!this.customerFullName) this.errors.push('Customer name missing')
        this.errors.map(error => toast.info(error))

    }



}