import React from 'react'
import "./SuspenseLoader.css"

const SuspenseLoader = () => {
  return (
   <div className='con'>
     <div className='loader'></div>
   </div>
  )
}

export default SuspenseLoader