import React, { useMemo, useState } from "react";
import { Button, EmptyState, Table, Text, TopButtons } from "Components";
import { assets } from "Assets";
import { Header } from "Partial/Header";
import { openModal } from "store/slices/modalSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { icons } from "Assets/icons";

import MakePayment from "./MakePayment";
import { Box } from "@mui/material";
// import { Category } from '@mui/icons-material';
import { useQuery } from "react-query";
// import { path } from 'utils';

const Invoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: categories } = useQuery("/collections/invoice-categories");
  const [status, setStatus] = useState("Paid");
  const [category, setCategory] = useState("Table");
  const columns = useMemo(() => {
    let cols = [
      { name: "Invoice number", key: "invoice_id" },
      { name: "Booking number", key: "booking_id" },
      { name: "Category", key: "category" },
      { name: "Amount (₦)", key: "total" },
      { name: "Invoice Date", key: "generated_on" },
    ];
    // cols.push(
    //   { name: '', key: 'role', render: (data) => <Button radius='100px' onClick={() => dispatch(openModal({ title: 'Confirm Payment', content: <MakePayment customer={data?.customer} /> }))}>Mark as Paid</Button> },)

    if (status === "Unpaid")
      return [
        ...cols,
        {
          name: "",
          key: "role",
          render: (data) => (
            <Button
              disabled={category === "Food Order"}
              radius="100px"
              onClick={() =>
                dispatch(
                  openModal({
                    title: "Confirm Payment",
                    content: (
                      <MakePayment data={data} customer={data?.customer} />
                    ),
                  })
                )
              }
            >
              Mark as Paid
            </Button>
          ),
        },
        // { name: '', key: 'role', render: (data) => <Button disabled={category === 'Food Order'} radius='100px' onClick={() => dispatch(openModal({ title: 'Confirm Payment', content: <MakePayment data={data} customer={data?.customer} /> }))}>Mark as Paid</Button> },
      ];
    return cols;
  }, [status, category, dispatch]);

  const path = useMemo(() => {
    const location = window.location.pathname;

    let path = location?.split("/")[1];
    return path;
  }, []);

  return (
    <>
      <Header
        name={"Invoices"}
        // text={'Create Invoice'}
        // buttonOnclick={() => navigate('/accounting/invoice/create')}
      >
        <Box width={"100%"}>
          <TopButtons
            btns={[
              { text: `Paid`, onClick: () => setStatus("Paid"), key: "table" },
              {
                text: `Unpaid`,
                onClick: () => setStatus("Unpaid"),
                key: "pending",
              },
            ]}
          />
        </Box>
      </Header>
      <Box
        className="my-3 flex items-center  w-[800px] py-3 pl-[40px]"
        overflow={"auto"}
      >
        Categories
        <TopButtons
          btns={
            categories?.data
              ?.filter((cate) => cate !== "Table")
              .map((cate) => ({
                text: cate,
                onClick: () => setCategory(cate),
                key: cate,
              }))

            // { text: `Bookings`, onClick: () => setCategory('Bookings'), key: 'Unpaid' },
            // { text: `Paid`, onClick: () => setStatus('Paid'), key: 'Paid' },
            // { text: `Unpaid`, onClick: () => setStatus('Unpaid'), key: 'Unpaid' },
            // { text: `Paid`, onClick: () => setStatus('Paid'), key: 'Paid' },
            // { text: `Unpaid`, onClick: () => setStatus('Unpaid'), key: 'Unpaid' },
          }
        />
      </Box>
      <Table
        appendQuery
        column={columns}
        emptyState={
          <EmptyState
            icon={assets.icons.staffMagt}
            text={"There are no  Manual Journals yet"}
          />
        }
        url={`/shop/secure/invoices?status=${status}&category=${category}`}
        // action={status === 'Paid'}
        actions={(data) => (
          <div>
            <Button
              bgcolor="inherit "
              shadow={false}
              color="black"
              onClick={() =>
                navigate(
                  `/${path}/invoice/view-details?invoice_id=${data?.invoice_id}`
                )
              }
            >
              {" "}
              {icons.edit} View Invoice
            </Button>

            {status === "Unpaid" && (
              <Button
                bgcolor="inherit "
                w="fit-content"
                onClick={() =>
                  navigate(
                    `/${path}/invoice/create?booking_id=${
                      data?.booking_id
                    }&category=${data?.category?.replace(
                      "-",
                      " "
                    )}&invoice_id=${data?.invoice_id}`
                  )
                }
              >
                <Text color="black">{icons.plus} Edit Invoice </Text>
              </Button>
            )}
            {/* <Button bgcolor='inherit ' shadow={false} color='#EB2F2F' onClick={() => dispatch(openModal({ title: 'Delete Confirmation', content: <DeleteModal message={'Delete staff '} url={`/admin/staff/${data.id}`} /> }))}>  {icons.delete} Delete</Button> */}
          </div>
        )}
      />
    </>
  );
};

export default Invoice;
