import { apiRequest } from "Services"
import { toast } from "react-toastify"

export const send_otp = async (phone, purpose, item) => {
    // {
    //     "phone": "2348056499531",
    //     "purpose": "order", // optional for now ['order', 'booking', 'signup', 'login'], only order & booking should work for now
    //     "item_id": "213" // optional for now (item_id) represent one of the above
    // }
    let payload = {
        phone, purpose
    }
    if (purpose == 'order') payload.item_id =  item?.id 

        try {
            const { data } = await apiRequest({
                payload,
                url: `/shop/otp`,
                method: 'post'
            })
            // console.log(data)
            toast.success('OTP sent to your mobile number')
            // toast.success(`Your mock otp is ${data?.data?.code}`)
        } catch (error) {
            throw error
        }
}
export const validate_otp = async (phone, code) => {

    try {
        const { data } = await apiRequest({
            payload: {
                phone,
                code
            }
            ,
            url: `/shop/otp/verify`,
            method: 'post'
        })
        return data
        // console.log(data)
        // toast.success('OTP sent to your mobile number')
        // toast.success(`Your mock otp is ${data?.data?.code}`)
    } catch (error) {
        // return error?.response?.data?.data
        throw error
    }
}
