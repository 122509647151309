import React from 'react'
import styles from './text.module.scss'
import { Text } from '.'

const FlexText = ({leftColor = '#000',rightColor='#000', leftText='left text', rightText=' ', height='30px',leftWeight, rightWeight}) => {
    return (
        <div className={styles.flextext} style={{height}}>
            <Text size='12px' weight={leftWeight || '400'} lHeight='10px' color={leftColor}> {leftText} </Text>
            <Text size='12px' weight={rightWeight || '400'} lHeight='10px' color={rightColor}>  {rightText} </Text>
        </div> 
    )
}

export default FlexText 