import React, { useState } from 'react'
import { Form, Input } from 'Components'
import { updateEmail } from './updateemail'

const UpdateEmail = ({ customer, onSuccess = () => null, isCustomer=false }) => {

    const [email, setEmail] = useState('')
    const proceed = (res) => {
        // console.log(res)

        onSuccess({ ...customer, email })

    }

    const submit = async () => {
        // alert('jh')
        try {
            
            // await updateEmail(customer, email, isCustomer)
            onSuccess({ ...customer, email })
        } catch (error) {

        }

    }
    return !customer?.email && <Form btnText='Proceed' btnProps={{ type: 'button', onClick: submit }} method={'patch'}>
        <Input
            name="email"
            label="Email"
            placeholder="Enter your email..."
            onChange={(e) => setEmail(e.target.value)}
        />
    </Form>


}

export default UpdateEmail