import { Button, CheckInput, Form, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import { changeTitle, closeModal } from 'store/slices/modalSlice'
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { apiRequest } from 'Services';
import { send_otp, validate_otp } from 'Services/otp';
import { useQuery } from 'react-query';
import { selectPay } from 'utils';
import { updateEmail } from 'Components/UpdateEmail/updateemail';
import UpdateEmail from 'Components/UpdateEmail';


const ConfirmPayment = ({ id, unique_id, order, onSuccess, customer }) => {
    // console.log(unique_id)
    const [payment, setPayment] = useState('')
    const [screen, setScreen] = useState('select')
    const [otp, setOtp] = useState('');
    const { data: methods } = useQuery('/collections/payment-methods')
    // console.log(methods?.data)
    const dispatch = useDispatch()

    const submit = async (ref) => {

        const references = ref?.paymentReference ? {
            references: ref
        } : {}
        const sendOtp___ = otp ? { otp } : {}
        // console.log(references, order.customer)
        try {
            await apiRequest({
                // url: `/shop/secure/orders/${id}/pay`,
                url: `/shop/secure/orders/pay`,

                payload: {
                    ...references, ...sendOtp___,
                    unique_id,
                    "payment_method": payment
                }, method: 'post', hasAuth: true
            })
            toast.success('Payment successful')
            setScreen('success')
            onSuccess()

        } catch (error) {
            // toast.error(error)
            const inSuff = error?.response?.data?.data?.error?.message
            if (inSuff === 'Insufficient balance!') {
                dispatch(closeModal())
            }

        }
    }

    const { data: customerDetails } = useQuery(`/operations/customers/${order?.customer?.id}`, { enabled: Boolean(order?.customer?.id) })
    // console.log(customerDetails?.data?.email)

    // const en


    const SelectPay = () => {

        const next = async () => {
            if (payment === 'Be-Hive Wallet') {
                setScreen('otpmessage')
            } else {
                await selectPay({
                    amount: order?.total,
                    method: payment,
                    desc: 'Food Ordering',
                    onBehive: () => setScreen('otppin'),
                    customer: { ...customerDetails?.data, full_name: customerDetails?.data },
                    next: (ref) => submit(ref),
                    onNoEmail: () => setScreen('updateEmail'),purpose:'order'
                })
            }
            // else {
            //     toast.info('Sorry! payment method not available, use wallet.')
            // }
        }
        return <Form btnText='Confirm ' onSubmit={next}>
            <div className='flex flex-col justify-start'>
                {/* <CheckInput label='Cash' checked={payment === 'Cash'} onChange={() => setPayment('Cash')} />
                <CheckInput label='EXTERNAL_BANK_TRANSFER' checked={payment === 'EXTERNAL_BANK_TRANSFER'} onChange={() => setPayment('EXTERNAL_BANK_TRANSFER')} />
                <CheckInput label='POS' checked={payment === 'Pos'} onChange={() => setPayment('Pos')} />
                <CheckInput label='Card' checked={payment === 'Card'} onChange={() => setPayment('Card')} />
                <CheckInput label='Bank Transfer' checked={payment === 'Bank Transfer'} onChange={() => setPayment('Bank Transfer')} Z />
                <CheckInput label='Be-Hive Wallet' checked={payment === 'Be-Hive Wallet'} onChange={() => setPayment('Be-Hive Wallet')} /> */}

                {methods?.data?.filter(method => method !== 'Unpaid').map(method => <CheckInput label={method} checked={payment === method} onChange={() => setPayment(method)} />)}

            </div>
        </Form>
    }
    const OtpMessage = () => {
        useEffect(() => {
            changeTitle('Be-Hive Wallet')
        }, [])

        return (
            <div className='w-full  flex gap-3 flex-col justify-center items-center'>
                <Text weight='600' size='16px'>Confirm payment using Be-hive wallet</Text>

                <Text weight='400' size='14px'>
                    We’ll send OTP verification code to customer phone number for verification. Customer will have to send you the code.
                </Text>

                <Button onClick={async () => {
                    try {
                        await send_otp(order?.customer?.phone,'order')
                        setScreen('otppin')

                    } catch (error) {
                        toast.error("An error occured")
                    }
                }}>
                    Make Request
                </Button>

            </div>
        )
    }
    const OtpPin = () => {
        useEffect(() => {
            changeTitle('Be-Hive Wallet')
        }, [])
        const checkPin = async () => {
            // alert('ff')
            try {
                await validate_otp(order?.customer?.phone, otp)
                submit(otp)

            } catch (error) {
                toast.error('Invalid otp, please try again')

            }


            // if (parseInt(otp) === password) {
            // } else {
            // }
        }
        return (
            <div className='w-full  flex gap-3 flex-col items-center'>
                <Text weight='600' size='16px'>Enter OTP code sent to customer phone number  </Text>

                <Text weight='400' size='14px'>
                    Please enter the 4-digit code that was sent to the customer phone number to proceed
                </Text>
                <Form onSubmit={checkPin} btnText='Proceed'>
                    <div className=' flex justify-center'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputStyle={{ background: "#EFEFEF", borderRadius: '12px', padding: '14px 0', margin: '0 6px', width: '64px' }}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input className='!bg-[#EFEFEF] !border p-[14px] hidden ' {...props} />}
                        />
                    </div>
                    {/* <Button onClick={checkPin}>
                        Proceed
                    </Button> */}
                </Form>

            </div>
        )
    }
    const Success = () => {
        useEffect(() => {
            changeTitle('Payment successful')
        }, [])
        return (
            <div className='w-full flex  flex-col gap-3 items-center'>
                <Text weight='600' size='24px'>  </Text>

                <Text weight='400' size='14px'>
                    You payment was successful proccessed
                </Text>
                <Button onClick={() => {
                    dispatch(closeModal())
                }}>
                    Dismiss
                </Button>

            </div>
        )
    }

    return (
        <div className='w-full'>
            {screen === 'select' && <SelectPay />}
            {screen === 'otpmessage' && <OtpMessage />}
            {screen === 'otppin' && <OtpPin />}
            {screen === 'updateEmail' && <UpdateEmail onSuccess={async (newCus) => {
                // console.log(newCus)
                await selectPay({
                    amount: order?.total,
                    method: payment,
                    desc: 'Food Ordering',
                    onBehive: () => setScreen('otppin'),
                    customer: { ...newCus, full_name: newCus?.full_name },
                    next: (ref) => submit(ref),
                    onNoEmail: () => setScreen('updateEmail'),purpose:'order'
                })
            }} customer={order?.customer} />}
            {screen === 'success' && <Success />}

        </div>
    )
}

export default ConfirmPayment