import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: {},
    trgger: false
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        assignCustomer: (state, { payload }) => {
            state.data = payload
        },
        setNew: (state, { payload }) => {
            state.trgger = true;
            // state.data = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { assignCustomer, setNew } = customerSlice.actions

export default customerSlice.reducer