
import { useSelector } from 'react-redux'
import { apiRequest } from '../Services'
import { useEffect, useState } from 'react'

const useUser = () => {
    const { details, access_token, refresh_token, isLoggedIn } = useSelector(state => state.user)
    return { details, access_token, refresh_token, isLoggedIn }
}
const useMe = () => {
    // const { details, access_token, refresh_token, isLoggedIn } = 
    const [me, setMe] = useState({})

    // const { access_token } = useSelector(state => state.user)
    useEffect(() => {
        const req = async () => {
            const data = await apiRequest({ url: 'auth/me', hasAuth: true })
            // console.log(data?.data?.data)
            setMe(data?.data?.data)
        }
        req()
    }, [])

    return {
        first_name: me?.first_name,
        last_name: me?.last_name,
        job_position: me?.job_position,
        phone: me?.phone,
        email: me?.email,
        avatar: me?.avatar,
        status: me?.status,
        id: me?.id || null,
        branch_id:me?.branch_id,
        role:me?.role
    }
}


export { useUser, useMe }