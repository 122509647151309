import { Box } from '@mui/material'
import { Form, Text } from 'Components'
import React from 'react'

const Mark = ({id}) => {
  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={'40px'} alignItems={'center'} mt={'14px'}>
    <Form btnText='Yes, mark as paid' method={'get'} url={`/operations/aging-receivables/${id}/mark-as-paid`}>

          {/* <Box my={'14px'} display={'flex'} justifyContent={'center'} width={'100%'}> */}
      <Text width='100%' align='center'>Are you sure you want to mark as paid?</Text>
      {/* </Box> */}

        {/* <Button></Button> */}
    </Form>
    </Box>
  )
}

export default Mark