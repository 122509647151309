import React from "react";

import { Button } from "../Button";
import { Box } from "@mui/material";
import { Text } from "../Text";
import { useDispatch } from "react-redux";
import { closeModal } from "../../store/slices/modalSlice";
import { apiRequest } from "../../Services";
import { toast } from "react-toastify";

const DeleteModal = ({
  message,
  onCancel = () => null,
  onDelete = () => null,
  deleteKey = {},
  method = "delete",
  url = "",
  payload,
  successMessage = "Deleted Successfully",
  btnText = "Delete",
}) => {
  const dispatch = useDispatch();
  const deleteItem = async () => {
    onDelete();
    try {
      await apiRequest({ method, url, payload, hasAuth: true });
      onDelete();

      toast.success(successMessage);
      dispatch(closeModal());
    } catch (error) {
      // console.log(error)
      toast.error(error);
    }
  };

  return (
    <Box textAlign={"center"}>
      <Box justifyContent={"center"} display={"flex"} mt="24px">
        {" "}
        <Text align={"center"}> {message}</Text>
      </Box>
      <Box width={"100%"} display={"flex"} gap={"16px"} mt={"64px"}>
        <Button
          bgcolor="#F7F7F7"
          color="#B8541B"
          shadow={false}
          onClick={() => {
            onCancel();
            dispatch(closeModal());
          }}
        >
          {" "}
          Cancel
        </Button>
        <Button onClick={deleteItem} shadow={false}>
          {" "}
          {btnText}{" "}
        </Button>
      </Box>
    </Box>
  );
};

export { DeleteModal };
