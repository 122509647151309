import { Box } from '@mui/material'
import { BtnStack, Button, FlexText, Form, Input, ProgressStep, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import ReviewConfirm from './ReviewConfirm'
import SuccessfulModal from './Successful'
import { useBranches } from 'hooks'
import { SelectCustomer } from './AssignCustomer'
import { apiRequest } from 'Services'
import { selectOpts } from 'utils'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import Explore from './Explore'
// import { NearestAvailabe } from './Nearest'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import ListItem from './ListItem'
import { formatDate } from 'utils/formatPrice'
import { NearestAvailableList } from './NearestAvailableList'
import HiveOtpPos from './HivePinPos'

const ReserveTable = ({ state = 0, cus = 'existing', customer }) => {
    const [current, setCurrent] = useState(state)
    const [formValues, setFormValues] = useState({})
    const [type, setType] = useState(null)
    const { branches } = useBranches()
    const [tables, setTables] = useState([])
    const [venues, setVenues] = useState([])
    const [table, setTable] = useState(null)
    const { data: venue_list } = useQuery(`/admin/branches/${formValues?.branch_id}/venues`, { enabled: Boolean(formValues?.branch_id) })
    const { data: table_list } = useQuery(`/shop/bookings/${formValues?.venue_id}/available/tables`, { enabled: Boolean(formValues?.venue_id) })
    const { data: nearest_table } = useQuery(`/shop/bookings/${formValues?.venue_id}/nearest-available/tables?date=${formatDate({ date: formValues?.date, type: 'backenddate' })}&time=${formatDate({ date: formValues?.time, type: 'backendtime' })}`,
        { enabled: Boolean(formValues?.venue_id && formValues?.date && formValues?.time) })

    useEffect(() => {
        venue_list?.data?.length ? setVenues(venue_list?.data) : setVenues([])
        table_list?.data?.length ? setTables(table_list?.data) : setTables([])
        if (!table_list?.data?.length && nearest_table?.data?.length) {
            setTables(nearest_table?.data)
        }
    }, [table_list, venue_list, nearest_table?.data])
    const [resPo, setRes] = useState('')
    const handleChange = (e) => {
        setFormValues(prev => ({ ...prev, [e?.target?.name]: e.target.value }))
    }
    const submit = async () => {
        try {
            const res = await apiRequest({
                method: 'post',
                payload: {
                    "table_id": table,
                    // "branch_id": formValues?.branch_id,
                    // "venue_id": formValues?.venue_id,
                    "date": dayjs(formValues?.date).format('YYYY-MM-DD'),
                    "time": dayjs(formValues?.time).format('hh:mm'),
                    "customer_type": customer?.type,
                    "type": "table",
                    "customer_id": customer?.id
                },
                url: '/shop/secure/bookings/table', hasAuth: true
            })
            setCurrent(3)
            setRes(res)
            console.log(res)
        } catch (error) {

        }
    }
    const moveOne = () => {
        if (formValues?.date && formValues?.time) {
            tables?.length ? setCurrent(11) : setCurrent(12)
        } else {
            toast.info('Select date and time')
        }
    }


    const One = () => (
        <>
            <Input required placeholder='Select branch' onChange={handleChange} type='select' option={selectOpts(branches, 'name')} name='branch_id' label='Select Branch' />
            <Input label='Venue' placeholder='Select venue' name='venue_id' type='select' option={selectOpts(venues)} onChange={handleChange} />
            {/* <Input placeholder='Select table number' name='table_id' onChange={handleChange} label='Select Table Number' type='select' option={tables} /> */}
            <Box display={'flex'} gap={'16px'}>
                <Input placeholder='Reserve date' name='date' defaultValue={formValues?.date} onChange={handleChange} type='date' label='Reserve Date' />
                <Input placeholder='Reserve date' name='time' defaultValue={formValues?.time} onChange={handleChange} type='time' label='Reserve Time' />
            </Box>
            <Button onClick={moveOne} >
                Proceed
            </Button>
        </>
    )
    const List = () => {
        return (
            <div className='flex flex-col gap-3 w-full'>
                {tables?.map(tableItem => <ListItem name={tableItem?.name} active={table} key={tableItem?.id} id={tableItem?.id} onClick={() => { setTable(tableItem?.id); toast.success(`Table ${tableItem.name} selected`) }} />)}
                <BtnStack leftClick={() => setCurrent(0)} rightClick={() => setCurrent(1)} />
            </div>

        )
    }

    return (
        <Form>
            {current < 2 && <ProgressStep points={[1, 2]} current={current} onChange={setCurrent} />}
            {current === 0 && One()}
            {current === 11 && <List />}
            {current === 12 && <Explore name={'tables'} back={() => setCurrent(0)} onExplore={() => setCurrent(13)} />}
            {current === 13 && <NearestAvailableList
                back={() => setCurrent(12)} list={nearest_table?.data} name={'table'}
                onAvailable={(id) => { setCurrent(1); setTable(id) }} >
                {nearest_table?.data.map(table => <ListItem name={table?.name} />)}
            </NearestAvailableList>}

            {current === 1 && <>

                <SelectCustomer onChange={setType} prev={() => setCurrent(0)} next={() => setCurrent(2)} >
                    <Box bgcolor={'#F7F7F7'} height={'8px'} width={'100%'} />
                    {/* <Input label='How will you like to pay?' placeholder={'Select payment method'} name='paymentMethod' onChange={handleChange} type='select' option={[
                        { label: 'Card', value: 'Card' }, { label: 'Bank Transfer', value: 'Bank Transfer' }, { label: 'Be-Hive Wallet', value: 'Be-Hive Wallet' },
                    ]} /> */}
                    <Text width='100%' align='left' size='12px' color='#4F4F4F' weight='600'>
                        SELECTED TABLE
                    </Text>

                    <Text width='100%' align='left'>{tables?.find(x => x?.id === table)?.name}
                    </Text>
                </SelectCustomer>
            </>}
            {current === 2 && <ReviewConfirm
                prev={() => setCurrent(1)}
                next={() => submit()}
            >
                <FlexText leftText='Customer ID' rightText={customer?.id} />
                <FlexText leftText='Customer name' rightText={customer?.full_name} />
                <FlexText leftText='Phone number' rightText={customer?.phone} />
                <FlexText leftText='Customer type' rightText={type === 'existing' ? 'Member' : 'Regular'} />
                <FlexText leftText='Membership' rightText={customer?.type} />
                <FlexText leftText='Selected table' rightText={tables?.find(x => x?.id == table)?.name} />
                <FlexText leftText='Reserved date' rightText={dayjs(formValues?.date).format('DD MMM YYYY')} />
                <FlexText leftText='Reserved time' rightText={dayjs(formValues?.time).format('h:mm A')} />
            </ReviewConfirm>

            }
            {/* {current === 3 && <HiveOtpPos back={()=>setCurrent(2)} paymentMethod={formValues?.paymentMethod} onProceed={submit}/>} */}
            {current === 3 && <SuccessfulModal message={resPo?.data?.data?.description} />}
        </Form>
    )
}
const mapStateToProps = function (state) {
    return {
        customer: state?.customer.data,
    }
}

export default connect(mapStateToProps)(ReserveTable);