

import React, { lazy } from 'react';
import { admin_routes } from "Pages/Admin";
import { operations_routes } from "Pages/Operations";
import { pos_routes } from 'Pages/Pos';
import { customer_routes } from 'Pages/Customers';
import { accounting_routes } from 'Pages/Accounting';
import Receipt from 'Components/Receipt';
import BookSnooker from 'Pages/Pos/Bookings/BookSnooker';
import SnookerReceipt from 'Components/Receipt/SnookerReceipt';
import InvoiceDetails from 'Pages/Accounting/Invoice/InvoiceDetails';
// import Profile from 'Pages/Admin/Profile';
const Home = lazy(() => import("Pages/Home"))
const NotFound = lazy(() => import('Pages/NotFound'));
const Kitchen = lazy(() => import('Pages/Kitchen'));
const Waiter = lazy(() => import('Pages/Waiter'));
const AddOrderWaiter = lazy(() => import('Pages/Waiter/AddOrderWaiter'));
const WaiterReport = lazy(() => import('Pages/Waiter/WaiterReport'));
const GlobalUser = lazy(() => import('Pages/GlobalUser'));
const Profile = lazy(() => import('Pages/Admin/Profile'));
// const layout = { header: true, sidebar: true }

export const routes = [
    { Component: <Home />, path: '/', layout: false, isPublic: true },
    { Component: <Home />, path: '/login', layout: false, isPublic: true },
    { Component: <Home />, path: '/:type', layout: false, isPublic: true },
    // { Component: <Home />, path: '/:type', layout: false, isPublic: true },
    ...admin_routes, ...operations_routes, ...pos_routes, ...customer_routes, ...accounting_routes,
    { Component: <Kitchen />, path: '/kitchen', layout: false, isPublic: false },
    { Component: <Waiter />, path: '/waiter', layout: false, isPublic: false },
    { Component: <BookSnooker />, path: '/waiter/snooker', layout: false, isPublic: false },
    { Component: <AddOrderWaiter />, path: '/waiter/add-order', layout: false, isPublic: false },
    { Component: <AddOrderWaiter />, path: '/waiter/add-order/:id', layout: false, isPublic: false },
    { Component: <WaiterReport />, path: '/waiter/report', layout: false, isPublic: false },
    { Component: <InvoiceDetails />, path: '/waiter/invoice/view-details', layout: false, isPublic: false },
    { Component: <NotFound />, path: '*', layout: false, isPublic: false },
    { Component: <GlobalUser />, path: '/home', layout: false, isPublic: false },
    { Component: <Profile />, path: '/profile', layout: false, isPublic: false },
    { Component: <Receipt />, path: '/receipt/:id', layout: false, isPublic: false },
    { Component: <SnookerReceipt />, path: '/snooker-receipt/:id', layout: false, isPublic: false },
]

