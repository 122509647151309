import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    access_token: '',
    isLoggedIn: false,
    refresh_token: '',
    loggedin_at:'',isDefaultPassword:null,
    details: {}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload: { access_token, refresh_token, user, path,expires_in, isDefaultPassword } }) => {
            // console.log(access_token)
            state.access_token = access_token;
            state.refresh_token = refresh_token;
            state.details = user;
            state.isLoggedIn = true;
            state.expires_in = expires_in;
            state.loggedin_at = new Date().getTime();
            state.isDefaultPassword = isDefaultPassword;
            window.location.assign(path)
        },
        // setLoggedinAt:(state)=>{
        //     state.loggedin_at = new Date().getTime();
        // },
        logout: (state) => {
            state.access_token = '';
            state.refresh_token = '';
            state.details = {};
            state.isLoggedIn = false;
            window.location.assign('/login')
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUser, logout } = userSlice.actions

export default userSlice.reducer