import React, { useMemo } from 'react'
import styles from './styles.module.scss'
import { Text } from 'Components'

const Status = ({ children, color = 'black', data }) => {
    const colors = {
        green: '#27AE60',
        red: '#EB2F2F',
        blue: '#2463FF',
        orange: 'orange',
        black: '#000000',
        yellow: '#9f6402'
    }
    const __color = useMemo(() => {
        const status = data?.status
        switch (status) {
            case 'Completed':
                return colors.green
            case 'Paid':
                return colors.green
            case 'Active':
                return colors.blue
            case 'Pending':
                return colors.orange
            case 'Canceled':
                return colors.red
            case 'Waiting Confirmation':
                return colors.yellow
            case 'Preparing':
                return colors.blue
            case 'Picked Up':
                return colors.green
            case 'Served':
                return colors.green

            default:
                return color
        }

    }, [data?.status])
    return (
        <div className={styles.status}>
            <Text weight='600' align='left' lHeight='18px' size='12px' color={__color ? __color : color}>
                {children}
            </Text>
        </div>
    )
}

export default Status