import React from 'react'
import styles from './header.module.scss'
import { Button, ButtonGroup, Text } from '../../Components'

import { useMediaQuery } from '@mui/material'
export const Header = ({ children, name, text, buttonOnclick = () => null, noGroup, export_name,onExport,filterComponent, clearFilter=()=>null, onSearch }) => {
    const click = () => {
        // alert('jk   ')
        buttonOnclick() 
    }
    const reduce = useMediaQuery('(max-width:600px)')
    return (
        <header className={styles.header}>
            <Text color='black' size={!reduce ? '24px' : '18px'} weight='600' lHeight='36px'>{name}</Text>
            {children}
            <div className={styles.btns}>
                {
                    !noGroup && <ButtonGroup clearFilter={clearFilter} onExport={onExport} export_name={export_name} filterComponent={filterComponent} onSearch={onSearch}>

                    </ButtonGroup>
                }
                {text && <Button w='fit-content' className={styles.rightBtn} onClick={click}>
                    {text}
                </Button>}
            </div>
        </header>
    )
}