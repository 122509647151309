import { apiRequest } from "Services";
import { selectOpts } from "./extraSelectOptions";
import { formatPrice } from "./formatPrice";
import dayjs from "dayjs";
import { selectPay } from "./selectPay";

const payment_structures = [
  { value: "full_payment", label: "Full Payment" },
  { value: "part_payment", label: "Part Payment" },
];
const payment_methods = [
  { value: "Bank Transfer", label: " Bank Transfer" },
  { value: "Card", label: "Card" },
  { value: "Be-Hive Wallet", label: "Be-Hive Wallet" },
];

const generateReceipt = async (id) => {
  // const res = await apiRequest({
  //     url: `/shop/secure/invoices/${id}/view-pdf`, hasAuth: true
  // })

  window.open(`/receipt/${id}`);
};

const downloadList = async (export_name, query) => {
  console.log(export_name);

  let download_url = "";
  download_url = `operations/exports/${export_name}/csv`;
  if (query) download_url = `operations/exports/${export_name}/csv?${query}`;
  const { data } = await apiRequest({ url: download_url, hasAuth: true });
  // console.log(data?.data)

  const link = document.createElement("a");
  const url = window.URL.createObjectURL(new Blob([data]));
  link.href = url;
  const fileName = `${export_name} ${dayjs(new Date()).format(
    "DD-MMM-YY"
  )}.csv`;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const location = window.location.pathname;

let path = location?.split("/")[1];
window.addEventListener("click", () => {
  // console.log(location?.split('/'))
  // if (location.includes('pos')) path = 'pos'
  // if (location.includes('operation')) path = 'operations'
  // if (location.includes('operation')) path = 'operations'
  path = location?.split("/")[1];
});

const getPath = () => {
  const location = window.location.pathname;

  let path = location?.split("/")[1];
  return path;
};

const keydownAvoid_e_input = (e, inputtype) => {
  let invalidChars = ["-", "+", "e"]; //include "." if you only want integers
  if (invalidChars.includes(e.key)) {
    if (inputtype === "number") {
      e.preventDefault();
      console.log("first");
    }
  }
};

const header_status = (status, is_paid) => {
  switch (status) {
    case "Ready":
      return [{ text: "READY TO SERVE", color: "#2463FF", bg: "#EDF3FF" }];
    case "Pending":
      return [{ text: "Pending", color: "#E58C00", bg: "#FDF8E6" }];
    case "Waiting Confirmation":
      return [
        { text: "Waiting Confirmation", color: "#E58C00", bg: "#FDF8E6" },
      ];
    case "Preparing":
      return [{ text: "Preparing", color: "#E58C00", bg: "#FDF8E6" }];
    case "Canceled":
      return [
        { text: "Canceled", color: "#B8541B", bg: "rgba(255, 0, 48, .2)" },
      ];
    case "Completed":
      return [
        {
          text: is_paid ? "Paid " : "Unpaid",
          color: is_paid ? "#27AE60" : "#E58C00",
          bg: is_paid ? "#E9F7EF" : "#FDF8E6",
        },
        { text: "COMPLETED", color: "#27AE60", bg: "#E9F7EF" },
      ];
    default:
      break;
  }
};
// const getPath

export {
  selectOpts,
  formatPrice,
  payment_structures,
  payment_methods,
  generateReceipt,
  downloadList,
  path,
  selectPay,
  getPath,
  header_status,
  keydownAvoid_e_input,
};
