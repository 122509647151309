import React, { useState } from 'react'
import { assets } from '../../../Assets'
import { Header } from '../../../Partial/Header'
import { useDispatch } from 'react-redux'
import { openModal } from '../../../store/slices/modalSlice'
import { icons } from '../../../Assets/icons'
import { Button, DeleteModal, EmptyState, Table } from 'Components'
import { BiPlus } from 'react-icons/bi';
import Status from 'Components/Status';
import WalletDetails from './components/walletDetails'
import FundWallet from './components/FundWallet';
import dayjs from 'dayjs';

const Funding = () => {
  const [state] = useState(false);
  const [modal] = useState({ title: 'Fund Wallet', content: <FundWallet /> })
  const dispatch = useDispatch()


  const columns = [
    { name: 'Date & Time', key: 'dateTime', render :(data)=>dayjs(data?.created_at).format("MMM DD YYYY hh:mm A")},
    { name: 'Customer Name', key: 'customerName', render:(data)=>data?.customer?.full_name},
    { name: 'Phone Number', key: 'phoneNumber', render:(data)=>data?.customer?.phone},
    { name: 'Account Number', key: 'accountNum',render:(data)=>data?.wallet?.account_no},
    { name: 'Amount', key: 'amount', },
    { name: 'Payment Method', key: 'payment_method', },
    { name: 'Funded By ', key: 'funded_by', render:(data)=>data?.funded_by?.first_name + ' ' + data?.funded_by?.last_name  },
    { key: 'status', name: 'Status' ,render: (data) => <Status  color='green'>Success</Status>},
  ]

  // console.log(funding?.data?.data)
  return (
    <>
      <Header name={'Fund Wallet'}
        text={<> <BiPlus />Fund Wallet</>}
        buttonOnclick={() => dispatch(openModal(modal))}
      />
      {
        state ?
          <EmptyState
            icon={assets.icons.funding}
            text={'This is currently empty'}
          />
          :
          <Table
            column={columns}
            // data={funding?.data?.data}
            url='/pos/customers/funding'
            action={false}
            actions={
              (data) => (
                <div>
                    <Button bgcolor='inherit ' shadow={false} color='black'
                    onClick={() => dispatch(openModal({ title: 'Wallet Details', content :<WalletDetails/>}))}
                  > {icons.delete} View Details</Button>
                  {/* <Button bgcolor='inherit' shadow={false} color='black'
                    onClick={() => dispatch(openModal({ title: 'Edit funding', content: <Transfer /> }))}
                  > {icons.edit} Edit</Button> */}
                  <Button bgcolor='inherit ' shadow={false} color='#EB2F2F'
                    onClick={() => dispatch(openModal({ title: 'Delete confirmation', content :<DeleteModal message={'Do you want to delete this branch?'} />}))}
                  > {icons.delete} Delete</Button>
                </div>
              )
            }
          />
      }
    </>
  );
};

export default Funding;