const { Pay } = require("Partial/Monify/monify");
const { send_otp } = require("Services/otp");

export const selectPay = async ({ method, amount, customer, desc, next, onBehive, onNoEmail, purpose, item }) => {

    const pay = new Pay(amount, customer, desc)
    switch (method) {
        case 'Card':
            if (customer?.email) {
                pay.withCard(next)
            } else {
                onNoEmail()
            }
            break;
        case 'Bank Transfer':
            if (customer?.email) {
                pay.withBank(next)
            } else { onNoEmail() }
            break;
        case 'Cash':
            next({})
            break;
        case 'Pos':
            next({})
            break;
        case 'External Bank Transfer':
            next({})
            break;
        case 'Be-Hive Wallet':
            try {
                await send_otp(customer?.phone, purpose, item)
                onBehive()
                // next()
            } catch (error) {
                console.log(error)
            }
            break
        default:
            break;
    }

}