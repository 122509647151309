import { Box } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss'
// import { Text } from 'Components'

const SearchResult = ({ children, customer }) => {
    return (
        <Box className={styles.body}>
            <Box className={styles.container}>
                {children}
                {/* <div>
                    <Text size='16px' weight='400' lHeight='24px'>Floyd Miles</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>(629) 555-0129</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>floyd.miles.@gmail.com</Text>
                </div>
                <div>
                    <Text size='16px' weight='400' lHeight='24px'>Floyd Miles</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>(629) 555-0129</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>floyd.miles.@gmail.com</Text>
                </div>
                <div>
                    <Text size='16px' weight='400' lHeight='24px'>Floyd Miles</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>(629) 555-0129</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>floyd.miles.@gmail.com</Text>
                </div>
                <div>
                    <Text size='16px' weight='400' lHeight='24px'>Floyd Miles</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>(629) 555-0129</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>floyd.miles.@gmail.com</Text>
                </div>
                <div>
                    <Text size='16px' weight='400' lHeight='24px'>Floyd Miles</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>(629) 555-0129</Text>
                    <Text size='12px' weight='600' lHeight='18px' color='#4F4F4F'>floyd.miles.@gmail.com</Text>
                </div> */}
            </Box>
        </Box>
    )
}

export default SearchResult