import { Box } from '@mui/material'
import { Button, Text } from 'Components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitle, closeModal } from 'store/slices/modalSlice'

const SuccessfulModal = ({ btnText = 'Dismiss', message = '' , title =  'Table reserved successfully', }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [dispatch,title])
    return (
        <Box display={'flex'} flexDirection={'column'} gap='16px' alignItems={'center'} >
            {/* <Text size='24px' lHeight='36px' weight='600' align='center' w='100%'>{title}</Text> */}
            <Text lHeight='24px' >
                {
                    message || ' You have successfully reserved table #3 for [Customer name] with membership type of [Membership type] and phone number [+233 44 555 5555]'
                }
            </Text>
            <Box mt={'24px'}>

            </Box>
            <Button onClick={()=>dispatch(closeModal())}> Dismiss</Button>
        </Box>
    )
}

export default SuccessfulModal