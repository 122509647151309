import React from 'react'
import styles from './actionmodal.module.scss'
import { Box } from '@mui/material'

const ActionModal = ({ onClickAway = () => null, children , top='45px', className}) => {
    return (
        <>
            <div className={styles.clickaway} onClick={onClickAway}></div>
            <Box className={`${styles.actionmodal} ${className}`}  top={top} onClick={onClickAway} >
                {children}
            </Box>
        </>
    )
}

export { ActionModal }