import React, { useState } from "react";
import styles from "./navbar.module.scss";
import { assets } from "../../Assets";
import { FiChevronDown } from "react-icons/fi";
import { Text } from "../../Components";
import { useUser } from "../../hooks";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { openModal } from "store/slices/modalSlice";
import EditProfile from "Pages/Admin/Profile/EditProfile";
import ChangePassword from "Pages/Admin/Profile/changePassword";
import { Home } from "@mui/icons-material";
import { logout } from "store/slices/userSlice";
import addNotification from "react-push-notification";
// import { Pay } from 'Partial/Monify/monify'

function Navbar({
  title,
  clickHambuger = () => null,
  right = false,
  className,
  text_className,
  FiChevronDown_color,
}) {
  const [showProfile, setShowProfile] = useState(false);
  const { details } = useUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(details)
  const toggleProfile = () => {
    setShowProfile((prev) => !prev);
  };
  const buttonClick = () => {
    // alert('kk')
    addNotification({
      title: "New Order",
      subtitle: "This is a subtitle",
      message: "This is a very long message",
      theme: "darkblue",

      // native: true // when using native, your OS will handle theming.
    });
  };

  // const pay = new Pay(2000,{name:'jhg',email:'kehinde@gmail.com',},'jhcrfc')
  return (
    <div className={`${className} ${styles.header}`}>
      <img src="/gurugeeks.png" alt="" width={80} height={80} />

      {title && (
        <Text color="white" weight="600" lHeight="36px" size="24px">
          {title}
        </Text>
      )}

      {
        <div className={styles.rightheader}>
          {right}
          <div
            className={styles.iconcircle}
            onClick={() => {
              navigate("/home");
              // pay.withBank()
              // buttonClick()
            }}
          >
            <Home />
          </div>
          <div className={styles.right}>
            <div className={` ${text_className}`} onClick={toggleProfile}>
              {details?.first_name}
              {""} {details?.last_name}
            </div>
            <div>
              <FiChevronDown
                cursor={"pointer"}
                color={`${FiChevronDown_color}`}
                onClick={toggleProfile}
              />
            </div>

            {showProfile && (
              <>
                <div className={styles.profile}>
                  <div className={` ${styles.innerProfile}`}>
                    <div
                      className={`border ${styles.iconcircle} text-white`}
                      onClick={() => navigate("/profile")}
                    >
                      {" "}
                      {details?.first_name?.charAt(0)}
                      {details?.last_name?.charAt(0)}{" "}
                    </div>
                    <div className={styles.innerRightProfile}>
                      <Text
                        size="14px"
                        isLink
                        lHeight="21px"
                        onClick={() => navigate("/profile")}
                      >
                        {details?.first_name} {details?.last_name}
                      </Text>
                      {/* <div className={styles.admin}>Admin</div> */}
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      dispatch(
                        openModal({
                          title: "Edit profile",
                          content: <EditProfile />,
                        })
                      )
                    }
                  >
                    {assets.icons.edit} Edit profile
                  </button>
                  <button
                    onClick={() =>
                      dispatch(
                        openModal({
                          title: "Change password",
                          content: <ChangePassword />,
                        })
                      )
                    }
                  >
                    {" "}
                    Change password{" "}
                  </button>
                  <button onClick={() => dispatch(logout())}>Log out</button>
                </div>{" "}
                <div onClick={toggleProfile} className={styles.clickAway}></div>
              </>
            )}
          </div>
        </div>
      }
    </div>
  );
}

export default Navbar;
