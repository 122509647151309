import { Close } from '@mui/icons-material'
import { assets } from 'Assets'
import { Button, EmptyState, Form, Input, Table, Text } from 'Components'
import { apiRequest } from 'Services'
import dayjs from 'dayjs'
import { useBranches } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { selectOpts } from 'utils'
import { formatDate } from 'utils/formatPrice'

const SaleMargin = () => {

//     Date
// Order ID
// Item
// Qty
// Total Margin
  const columns = [
      { name: 'Date', key: 'created_at', render: (data) => dayjs(data?.created_at).format('MMM DD, YYYY. hh:mm A') },
      { name: 'Branch', key: 'branch', },
    { name: 'Order Id', key: 'order_id', },
    { name: 'Item', key: 'item_name', },
    { name: 'Quantity', key: 'item_quantity'  },
    { name: 'Total Margin', key: 'gross_margin_total'},

  ]
  const [params, setParams] = useState({sort:'desc,created_at'})
  const [query, setQuery] = useState('')
  const [dates, setDates] = useState([])

  const handleChange = (name, value) => setParams(prev => ({ ...prev, [name]: value }))
  useEffect(() => {
    handleChange('date', dayjs(Date.now()).format('YYYY-MM-DD'))
  }, [])
  const generate = async () => {
    const str = (new URLSearchParams(params).toString())
    setQuery(`?${str}`)
    
    const { data: res } = await apiRequest({ url: `/reports/sales-margin/csv?${str}`, hasAuth: true, method: 'get' })
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([res]))
    link.href = url
    const fileName = `Sales Margin Report ${dayjs(new Date()).format("DD MMM YY")}.csv`;
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  useEffect(()=>{
    const str = (new URLSearchParams(params).toString())
    setQuery(`?${str}`)
  },[params])
  const clear = () => {
    setQuery('')
  }
  const { data: report } = useQuery(`/reports/sales-margin${query}`)
  // const { data: sources } = useQuery('/admin/inventory-source')

  const { branches } = useBranches()
  const handleDates = (e) => {
    const index = e.target.name
    const date = dayjs(e.target.value).format('YYYY-MM-DD')
    setDates(prev => {
      let values = prev;
      values[index] = date;
      return values
    })
    handleChange('created_at[btw]', JSON.stringify(dates))
  }

  return (
    <div className=' flex flex-col gap-4'>

      <div className='px-[40px]'>
        <Close onClick={clear} />
        <Form className='border flex justify-around items-center p-4 my-3 rounded-[12px]' direction={'row'}>
          <Input placeholder="From" label='From (date)' name='0' type='date' onChange={handleDates} />
          <Input placeholder="To" label='To (date)' name='1' type='date' onChange={handleDates} />
          <Input placeholder="Select branch" label='Select branch' name="branch"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type='select' option={selectOpts(branches,'name','name')} />
          {/* <Input placeholder="Date" label="File Type" type="select" option={[{ name: 'CSV', value: 'csv' }, { name: 'PDF', value: 'pdf' }]} name/> */}
          {/* <Input placeholder="Select House Type" label="House Type" type="select" onChange={(e) => handleChange(e.target.name, e.target.value)}
            option={[{ label: 'MOH', value: 'moh' }, { label: 'BOH', value: 'boh' }]} name={'with_inventory_source_type'} /> */}
          {/* <Input placeholder="Select Inventory Source" label="Inventory Source" type='select' name={'inventory_source'}
            option={selectOpts(sources?.data)} onChange={(e) => handleChange(e.target.name, e.target.value)} /> */}
          <Button className={'h-[100%] !mt-3'} onClick={generate}>Generate Report</Button>
        </Form>
        <div className=''>
          <Text weight='700' size='26px'>Sale Margin Report</Text>
        </div>
      </div>
      <Table
        column={columns}
        data={report?.data?.sales_margins}
        emptyState={<EmptyState icon={assets.icons.staffMagt} text={'There are no report  yet'} />}
        // url='/operations/reports/inventory-items'
        action={false}
      />
    </div>
  )
}

export default SaleMargin