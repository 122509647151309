// import { Box } from '@mui/material'
import {  Button, FlexText, Form, Input, ProgressStep } from 'Components'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitle } from 'store/slices/modalSlice'
import ReviewConfirm from './ReviewConfirm'
import SuccessfulModal from './Successful'
import { SelectCustomer } from './AssignCustomer'
// import { useBranches } from 'hooks'
import { selectOpts } from 'utils'
import { apiRequest } from 'Services'
import dayjs from 'dayjs'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
// import Explore from './Explore'

const BookSnookerModal = () => {
    const dispatch = useDispatch()
    const [current, setCurrent] = useState(0)
    const [formValues, setFormValues] = useState({})
    const [details, setDetails] = useState({})
    const [type, setType] = useState('existing')

    const handleChange = (e) => {
        setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const { data: fetch_list } = useQuery(`/shop/bookings/professional-services`)
    const list = fetch_list?.data?.filter(item => item.category === 'Snooker')
    console.log(list)
    useEffect(() => {
        dispatch(changeTitle('Snooker'))
    }, [dispatch])


    const submit = async () => {
        try {
            await apiRequest({
                payload: {
                    "service_id": formValues?.service_id,
                    "customer_id": details?.id,
                    "type": "Snooker",
                    "date": dayjs(formValues?.date).format('YYYY-MM-DD')

                },
                method: 'post',
                url:'/shop/secure/bookings/professional-service', hasAuth: true
            })
            setCurrent(3);
        } catch (error) {

        }
    }

    const moveOne = () => {
        if (formValues?.date && formValues?.service_id) {
            setCurrent(1)
        } else {
            toast.info('Please select a service and the day you want it')
        }
    }
    const One = () => (
        <>

            <Input label='Snooker' placeholder='Snooker table' name='service_id' defaultValue={formValues?.service_id} onChange={handleChange} type='select' option={selectOpts(list)} />

            <Input label='Date' type='date' name='date' placeholder='Select date' defaultValue={formValues?.date} onChange={handleChange} />
          

            <Button onClick={moveOne}>Proceed</Button>
        </>
    )


    return (
        <Form>
            {current < 2 && <ProgressStep points={[1, 2]} current={current} onChange={setCurrent} />}

            {current === 0 && <One />}
            {current === 1 &&
                <SelectCustomer prev={() => setCurrent(0)} next={() => setCurrent(2)} onChange={setType} getCustomer={setDetails}>
                    {type === 'existing' && <>

                      <hr /></>}
                    
                    <FlexText leftText='Service Name' rightText={list?.find(x => x?.id === parseInt(formValues?.service_id))?.name} rightWeight={'600'} />

                </SelectCustomer>}

            {current === 2 && <ReviewConfirm
                prev={() => setCurrent(1)}
                next={() => { submit() }}
            >
                <FlexText leftText='Selected snooker table' rightText={list?.find(x => x?.id === parseInt(formValues?.service_id))?.name} />
                <FlexText leftText={'Customer type'} rightText={details?.type} />
                <FlexText leftText={'Customer name'} rightText={details?.full_name} />
                <FlexText leftText={'Phone'} rightText={details?.phone} />
            </ReviewConfirm>}

            {current === 3 && <SuccessfulModal title='Snooker table booked  successfully' message='You have successfully booked a snooker table' />}
        </Form>
    )
}

export default BookSnookerModal