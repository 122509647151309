import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    title: '',
    content: '',
    isOpen: false
}

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        openModal: (state, { payload: { title, content } }) => {
            state.title = title;
            state.content = content
            state.isOpen = true
            // console.log(title,content)
        },
        closeModal: (state) => {
            state.isOpen = false
        },
        changeTitle: (state, { payload }) => {
            state.title = payload
        },
        changeContent: (state, { payload }) => {
            state.content = payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { openModal, closeModal, changeTitle } = modalSlice.actions

export default modalSlice.reducer