import { BtnStack } from 'Components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitle } from 'store/slices/modalSlice'

const ReviewConfirm = ({ submitText, next = () => null, prev = () => null, title = 'Review & Confirm', children }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [dispatch,title])
    const previous = () => {
      
        prev()
    }
    const nextClick = () => {
       
        next()
    }
    return (
        <>
            {children}
            <BtnStack
                leftClick={previous}

                rightClick={nextClick} />
        </>
    )
}

export default ReviewConfirm