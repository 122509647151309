import { BtnStack, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { changeTitle } from 'store/slices/modalSlice';

const HiveOtpPos = ({ onProceed = () => null, paymentMethod, back = () => null }) => {
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(changeTitle('Hive OTP '))
    }, [])
    const password = 1234
    const proceed = () => {
        if (paymentMethod === 'Be-Hive Wallet') {

            onProceed(parseInt(otp))

        } else {
            toast.error('Payment method error')
        }
    }
    return (
        <div className='flex flex-col gap-5 justify-center items-center !h-[100%]'>

            <Text weight='400' size='16px'>
                Enter the 4-digit code we sent to customer's phone number
            </Text>
            <div className='flex'>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputStyle={{ background: "#EFEFEF", borderRadius: '12px', padding: '14px 0', margin: '0 6px', width: '64px' }}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input className='!bg-[#EFEFEF] !border p-[14px] hidden ' {...props} />}
                />
            </div>
            <BtnStack leftClick={back} rightClick={proceed} />
        </div>
    )
}

export default HiveOtpPos